import { addCart, filterByBarcode } from '@/src/apis'
import { shop } from '@/src/assets'; // Adjust the path as needed
import { Footer, Header, Modal } from '@/src/components'
import { APP_INFO, DEV_MODE, ID_CART, LANDING_SITE_REF, RFID_SAMPLE_MAISON } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { uniqueEpcs, useLocalStorage } from '@/src/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { compareQuantities, groupDataByBarcode } from './utils'

const TIME_INTERVAL = 2000

const Shop: React.FC = () => {
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)

  const isMounted = useRef(false) // Track component mount state
  const [isModalErrorRFID, setIsModalErrorRFID] = useState<boolean>(false)

  const { t } = useTranslation()

  const { onLoading, onSetCart, isLoading, onSetOrder, onClearCart } = useApp()
  const { onReadAddRFID, isReadingRFID, RFID, setRFID } = useRFID()
  const { setUserInfo, setPhone, onSetBill } = useUser()

  const [appInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null) // Store interval ID

  const navigate = useNavigate()

  function isCheckValidateRFID(arr: { tag: string; barcode: string }[]): boolean {
    return arr.every((item) => item.barcode !== '')
  }

  const onClearTransaction = async () => {
    setIdCart(null)
    onSetOrder(null)
    onClearCart()
    setRFID([])
    setUserInfo(null)
    setPhone('')
    onSetBill()

    //
    setIsModalErrorRFID(!isModalErrorRFID)
    navigate('/home')
  }

  const onAddCart = useCallback(
    async (data?: any) => {
      let isValidRFID = isCheckValidateRFID(data)
      if (isValidRFID) {
        onLoading(true)

        const barcodes = data?.map((item: any) => item.barcode)
        const product = await filterByBarcode(barcodes)

        const dataByBarcode = groupDataByBarcode(data)

        // FILTER VARIANT_ID BY BARCODE PRODUCT
        const barcodeToVariantId = product.data.data.data?.reduce((acc: any, product: any) => {
          product.variants.forEach((variant: any) => {
            acc[variant.barcode] = variant.id
          })
          return acc
        }, {})

        // FILTER LINEITEMS => PAYLOAD ADD TO CART
        const lineItems: any[] = dataByBarcode
          .map((item: any) => {
            const variantId = barcodeToVariantId[item.barcode]
            return {
              variantId,
              quantity: item.qty
            }
          })
          .filter((item: any) => item.variantId)

        if (lineItems) {
          const payload = {
            lineItems,
            attributes: [
              {
                key: 'x-haravan-device',
                value: appInfo?.deviceCode || ''
              }
            ],
            locationId: appInfo?.location?.id,
            channel: 'pos',
            utmParameters: {
              landingSiteRef: LANDING_SITE_REF
            },
            allowPurchaseWhenSoldOut: true
          }

          const res = await addCart(payload)
          if (res) {
            await setIdCart(res?.data?.id)
            await onSetCart()

            // // KIỂM TRA XEM CÓ MÃ RFID NÀO BỊ LỖI HAY KHÔNG => KHÔNG TỒN TẠI SAN PHẨM TRONG SHOP
            const isValidateRFID = compareQuantities(dataByBarcode, lineItems)

            onLoading(false)
            navigate('/cart', { state: { isValidateRFID: isValidateRFID } })
          }
        }
      } else {
        setIsModalErrorRFID(true)
      }
    },
    [RFID]
  )

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!devMode?.isDisableRFID) {
        onReadAddRFID(isReadingRFID)
      }
    }, TIME_INTERVAL)

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isReadingRFID])

  useEffect(() => {
    if (isMounted.current) {
      if (!devMode?.isDisableRFID) {
        if (RFID?.length > 0) {
          onAddCart(uniqueEpcs(RFID))
        }
      }
    } else {
      isMounted.current = true
    }
  }, [RFID])

  useEffect(() => {
    if (devMode?.isDisableRFID) {
      const fetchData = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000)) // Remove the class after animation duration
        onAddCart(RFID_SAMPLE_MAISON)
        setRFID(RFID_SAMPLE_MAISON)
      }

      // Call the async function
      fetchData()
    }
  }, [])

  return (
    <div className='layout mt-[120px]'>
      <Header title={'shop.addItems'} />

      <p className={'responsive-text-small  text-center px-32 text-black mt-20'}>{t('shop.placeItemsOrBasket')}</p>
      <img style={{ height: '800px' }} src={shop} alt='img' />
      {!isLoading && <Footer isDisabledNext isDisabledBack={false} />}

      <Modal title={'shop.rfidError'} isModal={isModalErrorRFID} txtLeft={'close'} onActionLeft={onClearTransaction} />
    </div>
  )
}

export default Shop

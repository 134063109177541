import { ProviderProps, UserContextType } from '@/src/types'
import React, { createContext, useContext, useMemo, useState } from 'react'
const UserContext = createContext<UserContextType | undefined>(undefined)

import { INIT_INVOICE_PERSONAL } from '@/src/constants'

export const UserProvider: React.FC<ProviderProps> = ({ children }) => {
  const [phone, setPhone] = useState<string>('')
  const [bill, setBill] = useState<any>(INIT_INVOICE_PERSONAL)
  const [userInfo, setUserInfo] = useState<any>(null)

  const onSetBill = () => {
    setBill(INIT_INVOICE_PERSONAL)
  }

  const value = useMemo(
    () => ({
      phone,
      setPhone,
      bill,
      setBill,
      onSetBill,
      userInfo,
      setUserInfo
    }),
    [phone, bill, userInfo]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within an UserProvider')
  }
  return context
}

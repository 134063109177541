import { searchProduct, updateDeviceCode } from '@/src/apis/'
import { Header, PrintOrder } from '@/src/components'
import {
  APP_INFO,
  KEY_BOARD_TEXT_TYPE_PIN,
  LOCATION,
  ORDER,
  PIN_SETTING,
  SETTINGS,
  TOKEN,
  VERSION_APP,
  WHITE_LIST_DEV_MODE
} from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { decodeToken, printHTML, registerPrinter, showToast, useLocalStorage } from '@/src/utils'
import { scanner } from '@/src/utils/rfid'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Settings: React.FC = () => {
  const navigate = useNavigate()
  const { isModalPIN, setIsModalPIN } = useApp()

  const onBack = async () => {
    await setIsModalPIN(true)
    navigate('/home')
  }

  return (
    <div className='layout pb-40'>
      <Header title='settings.setting' onBack={onBack} />
      <Body />
      <div className='h-28' />
      {isModalPIN && <ModalPIN setIsModalPIN={setIsModalPIN} />}
    </div>
  )
}

//viewChildren
const Body: React.FC<any> = () => {
  const [token, setToken] = useLocalStorage<IAppInfoLocalStorage | string>(TOKEN, '')
  // @ts-ignore
  const [appInfo, setAppInfo, clearValue, clearAllValue] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const htmlRef = useRef<HTMLDivElement>(null)
  const [printTemplate, setPrintTemplate] = useState<any>(null)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const onClick = async (link: string) => {
    navigate(link)
  }

  const mapToValue = (key: string): string => {
    const { deviceCode, location, paymeCode, higd } = appInfo ?? {}

    if (key === 'DEVICE CODE') {
      return deviceCode ?? ''
    }

    if (key === 'LOCATIONS') {
      if (!location) return ''
      const { countryName, provinceName, districtName, wardName, address, name, phone, email } = location
      const parts = [countryName, provinceName, districtName, wardName, address, name, phone, email].filter(
        (part) => part
      )

      return parts.join(' - ') // Nối các phần lại với nhau bằng dấu " - "
    }

    if (key === 'PAYME CODE') {
      return paymeCode ?? ''
    }

    if (key === 'HiGD') {
      if (!higd) {
        return ''
      }

      const { ip_1, ip_2 } = higd
      if (ip_1 === undefined && ip_2 === undefined) {
        return ''
      } else {
        return [ip_1, ip_2].filter((ip) => ip?.trim() !== '').join(',')
      }
    }
    return ''
  }

  const onLogout = () => {
    setToken('')
    navigate('/admin')
  }

  const onPrintTest = async () => {
    if (htmlRef.current === null) return

    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printHTML(dataPrint)
  }

  useEffect(() => {
    if (htmlRef.current) onPrintTest()
  }, [printTemplate])

  const onPrintTemplate = async () => {
    // Lấy danh sách barcode từ sản phẩm trong đơn hàng
    const barcodes = ORDER?.orderProducts?.map((item: any) => item.barcode).join(',')

    // Tìm sản phẩm dựa trên barcode và vị trí
    const product = await searchProduct(barcodes, appInfo?.location?.id)

    // Nếu tìm thấy sản phẩm trong cơ sở dữ liệu
    if (!product?.is_error) {
      const mergedData = ORDER?.orderProducts?.map((order: any) => {
        const productDetails = product?.data?.data?.data?.find((product: any) => product.barcode === order.barcode)

        // Chỉ lấy tham số oldPrice từ productDetails
        return {
          ...order,
          oldPrice: productDetails?.oldPrice ?? null
        }
      })

      // Nếu dữ liệu đã hợp nhất tồn tại
      if (mergedData?.length) {
        const temp = {
          ...ORDER,
          orderProducts: mergedData
        }

        setPrintTemplate(temp) // Uncomment nếu cần thiết
      }
    }
  }

  const CONFIGS = [
    {
      id: 0,
      title: `${t('settings.rfid').toLocaleUpperCase()}`,
      onClick: async () => {
        const device = await scanner.pairDevice()
        if (device) {
          setAppInfo({
            ...appInfo,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          } as IAppInfoLocalStorage)

          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          })
        }
      }
    },
    {
      id: 1,
      title: `${t('settings.printer').toLocaleUpperCase()}`,

      onClick: async () => {
        const device = await registerPrinter()
        if (device) {
          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            printer: { vendorId: device.vendorId, productId: device.productId }
          })
        }
      }
    },
    {
      id: 2,
      title: `${t('settings.logOut').toLocaleUpperCase()}`,
      onClick: () => onLogout()
    },
    {
      id: 3,
      title: `${t('settings.restart').toLocaleUpperCase()}`,
      onClick: () => {
        window.location.reload()
      }
    },
    {
      id: 3,
      title: `${t('settings.testPrint').toLocaleUpperCase()}`,
      onClick: () => {
        onPrintTemplate()
      }
    }
    // {
    //   id: 3,
    //   title: `${t('settings.rfidTool').toLocaleUpperCase()}`,
    //   onClick: () => navigate('/settings-rfid-tool')
    // }
  ]

  const isDisbledSetting = useMemo(() => {
    return appInfo?.deviceCode ? false : true
  }, [appInfo])

  const isDevMode = useMemo(() => {
    if (!token) return false
    const decoded = decodeToken(token as string)

    if (WHITE_LIST_DEV_MODE.includes(decoded?.orgid)) return true
    return false
  }, [token])

  return (
    <div className={'flex flex-col h-screen w-screen items-center pt-20 gap-5 mb-20 bg-white'}>
      {SETTINGS?.map((val, index) => {
        const isDisabledButton = val?.key === 'deviceCode' ? false : isDisbledSetting
        return (
          <div className={'w-3/5'} key={index}>
            <p className='responsive-text-small'>{t(`settings.${val.key}`)}</p>

            <div className='flex justify-center imtems-center mt-4'>
              <div className='w-full min-h-28 px-2.5 border-2  rounded-lg flex items-center'>
                <p className={'text-[#9ca3af] responsive-text-small-2 break-all'}>{mapToValue(val.title)}</p>
              </div>
              <button
                disabled={isDisabledButton}
                onClick={() => onClick(val.link)}
                className={`${isDisabledButton ? 'btn-disabled' : 'btn-custom'}  background-accent700 h-28 w-1/3 rounded-lg ml-10`}
              >
                <p className={'responsive-text-small-3 text-white'}>{t(`settings.edit`)}</p>
              </button>
            </div>
          </div>
        )
      })}

      <div className='flex w-3/5 mt-10 flex-wrap justify-between gap-y-8'>
        {CONFIGS?.map((val, index) => {
          return (
            <button
              key={index}
              onClick={() => val.onClick()}
              className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}
            >
              <p className={'responsive-text-small-3 text-white'}>{val.title}</p>
            </button>
          )
        })}

        {isDevMode && <DEV_MODE />}
      </div>

      <p className='mt-20 italic'>Version {VERSION_APP}</p>

      {printTemplate && (
        <div className='absolute -top-[200rem] -z-10'>
          <div className='w-[550px] pr-5' ref={htmlRef}>
            <PrintOrder order={ORDER} location={LOCATION} />
          </div>
        </div>
      )}
    </div>
  )
}

//view
const DEV_MODE = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/settings-dev-mode')
  }

  return (
    <button onClick={onClick} className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}>
      <p className={'responsive-text-small-2 text-white'}>DEV MODE</p>
    </button>
  )
}

const ModalPIN: React.FC<any> = ({ setIsModalPIN }) => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [pin, setPin] = useState<string>('')
  const [targetIndex, setTargetIndex] = useState<number>(0)
  const [isPIN, setIsPIN] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const onKeyboardClick = (val: string) => {
    if (val === 'backspace') {
      setPin(pin.slice(0, -1))
      if (targetIndex > 0) {
        setTargetIndex(targetIndex - 1)
      }
    } else if (val === 'reset') {
      setPin('')
      setTargetIndex(0)
    } else {
      if (pin?.length > 5) return
      setPin(pin + val)
      setTargetIndex(targetIndex + 1)
    }
  }

  const onPIN = async () => {
    if (pin.length === 6 && pin === PIN_SETTING) {
      setIsModalPIN(false)
    } else {
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      showToast('warn', t('toast.pinSettingError'))
      return
    }
  }

  const onBack = () => {
    setIsPIN(false)
  }

  const onCancel = () => {
    navigate(-1)
  }

  const _pin = useMemo(() => {
    return pin.toString().split('').map(Number)
  }, [pin])

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[200] '>
        <div className='relative  w-5/6 '>
          <div
            className='
          rounded-lg shadow-lg relative w-full bg-white p-8 flex flex-col justify-center items-center gap-8'
          >
            {isPIN ? (
              <PIN
                divRef={divRef}
                _pin={_pin}
                targetIndex={targetIndex}
                onKeyboardClick={onKeyboardClick}
                onBack={onBack}
                onPIN={onPIN}
              />
            ) : (
              <>
                <button
                  onClick={() => navigate('/settings-printer')}
                  className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}
                >
                  <p className={'responsive-text-small-3 text-white'}>{t('settings.printerOrder')}</p>
                </button>

                <button
                  onClick={() => setIsPIN(true)}
                  className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}
                >
                  <p className={'responsive-text-small-3 text-white'}>{t('settings.setting')}</p>
                </button>

                <button
                  onClick={onCancel}
                  className={`btn-custom background-white h-28 w-[30%] rounded-lg border-2 border-[#0047BB] `}
                >
                  <p className={'responsive-text-small-3 content-accent700'}>{t('close')}</p>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='opacity-95 fixed inset-0 z-[100] bg-black'></div>
    </>
  )
}

const PIN: React.FC<any> = ({ divRef, _pin, targetIndex, onKeyboardClick, onBack, onPIN }) => {
  const { t } = useTranslation()

  const isBold = (key: string) => {
    return key === 'reset' || key === 'backspace'
  }

  return (
    <>
      <p className='responsive-text-small'>{t('settings.pin')}</p>
      <div ref={divRef} className='flex justify-between gap-4'>
        <div ref={divRef} className='flex justify-between gap-4'>
          <PIN_CODE val={_pin[0]} isTarget={0 === targetIndex} />
          <PIN_CODE val={_pin[1]} isTarget={1 === targetIndex} />
          <PIN_CODE val={_pin[2]} isTarget={2 === targetIndex} />
          <PIN_CODE val={_pin[3]} isTarget={3 === targetIndex} />
          <PIN_CODE val={_pin[4]} isTarget={4 === targetIndex} />
          <PIN_CODE val={_pin[5]} isTarget={5 === targetIndex} />
        </div>
      </div>

      <div className='w-4/6 flex flex-wrap gap-x-5 gap-y-2 mt-10 '>
        {KEY_BOARD_TEXT_TYPE_PIN.map((val: any, index: any) => {
          if (val === 'br') {
            return <div key={index} className='w-full'></div> // Tạo dòng mới
          } else {
            return (
              <button
                onClick={() => onKeyboardClick(val)}
                key={index}
                type='button'
                className={`flex-grow ${isBold(val) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} py-8 rounded-[16px] btn-custom-input`}
              >
                {val === 'backspace' && <Icon title='backspace' />}
                {val === 'reset' && <Icon title='clear' />}
                {val !== 'backspace' && val !== 'reset' && <p className='responsive-text-small'>{val}</p>}
              </button>
            )
          }
        })}
      </div>
      {/*  */}
      <div className={`flex items-center mt-8 gap-8 justify-center `}>
        <button onClick={onBack} className=' w-[360px]	h-[100px]  rounded-lg border-2 border-[#0047BB]'>
          <p className={`content-accent700 responsive-text-small-2`}>{t('back')}</p>
        </button>
        <button onClick={onPIN} className='background-accent700 w-[360px]	h-[100px] rounded-lg'>
          <p className={`text-white responsive-text-small-2`}>{t('confirm')}</p>
        </button>
      </div>
    </>
  )
}

const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons responsive-text-small'>{title}</i>
}
const PIN_CODE: React.FC<{ val: number; isTarget: boolean }> = ({ val, isTarget }) => {
  return (
    <div className='w-32 h-32  bg-[#E2E7EF] items-center justify-center flex pt-5'>
      <p className={`responsive-text-small font-bold text-center ${isTarget ? 'animate-pulse' : ''} `}>
        {val >= 0 ? '*' : '_'}
      </p>
    </div>
  )
}
export default Settings

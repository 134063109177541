import { AppProvider, RFIDProvider, UserProvider } from '@/src/context'
import i18next from '@/src/i18n'
import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from './App'
import { Loading, ScrollToTop } from './components'
import './styles/globals.css'

if (process.env.NODE_ENV !== 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 0.5, // tỷ lệ mẫu cho việc thu thập trace (dữ liệu về hiệu suất).
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // 10% : tỷ lệ mẫu cho việc ghi lại session replay.
    replaysOnErrorSampleRate: 1.0, // tỷ lệ mẫu cho việc ghi lại các session khi xảy ra lỗi.
    environment: 'production'
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <AppProvider>
          <UserProvider>
            <RFIDProvider>
              <App />
            </RFIDProvider>
          </UserProvider>
          <ToastContainer />
          <Loading />
          <ScrollToTop />
        </AppProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
)

import { createCustomer, getCustomerProfile } from '@/src/apis'
import { alpha, caps, remove } from '@/src/assets'
import { Footer, Header } from '@/src/components'
import {
  FIRST_NAME_REGEX,
  KEY_BOARD_NUMBER_2,
  KEY_BOARD_TEXT_TYPE_1,
  KEY_BOARD_TEXT_TYPE_2,
  KEY_BOARD_TEXT_TYPE_REGISTER,
  PHONE_REGEX
} from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { showToast } from '@/src/utils'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const combineCharacterAndTone = (base: string, tone: string): string => {
  const combinations: Record<string, Record<string, string>> = {
    a: { '̀': 'à', '́': 'á', '̉': 'ả', '̃': 'ã', '̣': 'ạ' },
    ă: { '̀': 'ằ', '́': 'ắ', '̉': 'ẳ', '̃': 'ẵ', '̣': 'ặ' },
    â: { '̀': 'ầ', '́': 'ấ', '̉': 'ẩ', '̃': 'ẫ', '̣': 'ậ' },
    e: { '̀': 'è', '́': 'é', '̉': 'ẻ', '̃': 'ẽ', '̣': 'ẹ' },
    ê: { '̀': 'ề', '́': 'ế', '̉': 'ể', '̃': 'ễ', '̣': 'ệ' },
    i: { '̀': 'ì', '́': 'í', '̉': 'ỉ', '̃': 'ĩ', '̣': 'ị' },
    o: { '̀': 'ò', '́': 'ó', '̉': 'ỏ', '̃': 'õ', '̣': 'ọ' },
    ô: { '̀': 'ồ', '́': 'ố', '̉': 'ổ', '̃': 'ỗ', '̣': 'ộ' },
    ơ: { '̀': 'ờ', '́': 'ớ', '̉': 'ở', '̃': 'ỡ', '̣': 'ợ' },
    u: { '̀': 'ù', '́': 'ú', '̉': 'ủ', '̃': 'ũ', '̣': 'ụ' },
    ư: { '̀': 'ừ', '́': 'ứ', '̉': 'ử', '̃': 'ữ', '̣': 'ự' },
    y: { '̀': 'ỳ', '́': 'ý', '̉': 'ỷ', '̃': 'ỹ', '̣': 'ỵ' },
    d: { '': 'đ' },
    A: { '̀': 'À', '́': 'Á', '̉': 'Ả', '̃': 'Ã', '̣': 'Ạ' },
    Ă: { '̀': 'Ằ', '́': 'Ắ', '̉': 'Ẳ', '̃': 'Ẵ', '̣': 'Ặ' },
    Â: { '̀': 'Ầ', '́': 'Ấ', '̉': 'Ẩ', '̃': 'Ẫ', '̣': 'Ậ' },
    E: { '̀': 'È', '́': 'É', '̉': 'Ẻ', '̃': 'Ẽ', '̣': 'Ẹ' },
    Ê: { '̀': 'Ề', '́': 'Ế', '̉': 'Ể', '̃': 'Ễ', '̣': 'Ệ' },
    I: { '̀': 'Ì', '́': 'Í', '̉': 'Ỉ', '̃': 'Ĩ', '̣': 'Ị' },
    O: { '̀': 'Ò', '́': 'Ó', '̉': 'Ỏ', '̃': 'Õ', '̣': 'Ọ' },
    Ô: { '̀': 'Ồ', '́': 'Ố', '̉': 'Ổ', '̃': 'Ỗ', '̣': 'Ộ' },
    Ơ: { '̀': 'Ờ', '́': 'Ớ', '̉': 'Ở', '̃': 'Ỡ', '̣': 'Ợ' },
    U: { '̀': 'Ù', '́': 'Ú', '̉': 'Ủ', '̃': 'Ũ', '̣': 'Ụ' },
    Ư: { '̀': 'Ừ', '́': 'Ứ', '̉': 'Ử', '̃': 'Ữ', '̣': 'Ự' },
    Y: { '̀': 'Ỳ', '́': 'Ý', '̉': 'Ỷ', '̃': 'Ỹ', '̣': 'Ỵ' },
    D: { '': 'Đ' } // Chữ 'D' thành 'Đ'
  }

  return combinations[base]?.[tone] || base
}

const convertYYYYMMDD = (date: string) => {
  return `${date.slice(4, 8)}/${date.slice(2, 4)}/${date.slice(0, 2)}`
}

const INPUT = [
  {
    id: 0,
    title: 'register.inputLastName',
    placeholder: 'register.enterLastName',
    key: 'lastName',
    isRequired: true
  },
  {
    id: 1,
    title: 'register.inputFirstName',
    placeholder: 'register.enterFirstName',
    key: 'firstName',
    isRequired: true
  },
  {
    id: 2,
    title: 'register.inputPhone',
    placeholder: 'register.enterPhone',
    key: 'phone',
    isRequired: true
  }
]

const Register: React.FC = () => {
  const { setUserInfo, setPhone } = useUser()
  const { onClearCart, onLoading } = useApp()
  const { setRFID, setIsReadingRFID } = useRFID()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { phone } = useParams()
  const [customerInfo, setCustomerInfo] = useState<any>({
    firstName: '',
    lastName: '',
    phone: '',
    birthday: ''
  })
  const [selectedKey, setSelectedKey] = useState<string>('')
  const [keyboard, setIsKeyboard] = useState(KEY_BOARD_TEXT_TYPE_REGISTER)
  const [isCaps, setIsCaps] = useState(false)

  const inputRefs = useRef<any>([])

  const setRef = (index: number, node: any | null) => {
    if (node) {
      inputRefs.current[index] = node
    }
  }

  useEffect(() => {
    if (phone) {
      setCustomerInfo({ ...customerInfo, phone })
    }
  }, [phone])

  useEffect(() => {
    setSelectedKey(INPUT[0].key)
  }, [INPUT])

  const _onClearCart = () => {
    onClearCart()
    setRFID([])
    setIsReadingRFID(false)
  }

  const onKeyboardClick = (key: string) => {
    if (!selectedKey) return

    let temp = key.toLowerCase()

    if (isCaps && key !== 'gmail.com' && key !== '.com' && key !== '.vn') {
      temp = key.toUpperCase()
    }

    switch (key) {
      case 'backspace':
        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: customerInfo[selectedKey].slice(0, -1)
        }))
        break
      case 'reset':
        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: ''
        }))
        break
      case 'caps':
        setIsCaps(!isCaps)
        break
      case 'space':
        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: customerInfo[selectedKey] + ' '
        }))
        break
      case 'textformat':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_1)
        break
      case '!?#':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_2)
        break
      default:
        const toneMarks = ['̀', '́', '̉', '̃', '̣']
        if (toneMarks.includes(key)) {
          setCustomerInfo((prevState: any) => {
            const currentValue = prevState[selectedKey]
            const lastChar = currentValue?.slice(-1)
            const newChar = combineCharacterAndTone(lastChar, key)
            return {
              ...prevState,
              [selectedKey]: currentValue.slice(0, -1) + newChar
            }
          })
          return
        }

        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: customerInfo[selectedKey] + temp
        }))
    }
  }

  const isKeyBold = (key: string): boolean =>
    ['textformat', '!?#', 'reset', 'backspace', '̀', '́', '̉', '̃', '̣'].includes(key)

  const onClick = (val: string) => {
    try {
      if (val === 'backspace') {
        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: customerInfo[selectedKey].slice(0, -1)
        }))
      } else if (val === 'reset') {
        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: ''
        }))
      } else {
        if (selectedKey === 'birthday' && customerInfo['birthday']?.length >= 8) return

        setCustomerInfo((prevState: any) => ({
          ...prevState,
          [selectedKey]: customerInfo[selectedKey] + val
        }))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const isBold = (val: string) => {
    let temp = false
    if (val === 'reset' || val === 'backspace') {
      temp = true
    }

    return temp
  }

  const isValidFirstName = async () => {
    const isValid = FIRST_NAME_REGEX.test(customerInfo['firstName'].trim())
    if (!isValid) {
      inputRefs.current['firstName']?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      inputRefs.current['firstName']?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidFirstName'))
    }
    return isValid
  }

  const isValidLastName = async () => {
    const isValid = FIRST_NAME_REGEX.test(customerInfo['lastName'].trim())
    if (!isValid) {
      inputRefs.current['lastName']?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      inputRefs.current['lastName']?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidLastName'))
    }
    return isValid
  }

  const isValidCustomer = async (): Promise<boolean> => {
    let isDataValid = true

    const requiredFields = getRequiredFields()

    for (const field of requiredFields) {
      if (!customerInfo[field]) {
        shakeInput(field)
        showToast('warn', t('toast.pleaseEnterValue'))
        isDataValid = false
      }
    }

    return isDataValid
  }

  const isValidPhone = async (): Promise<boolean> => {
    const isValid = PHONE_REGEX.test(customerInfo['phone'].trim())
    if (!isValid) {
      inputRefs.current['phone']?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      inputRefs.current['phone']?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidPhoneNumber'))
    }
    return isValid
  }

  const isValidBirthday = async (): Promise<boolean> => {
    const currentYear = new Date().getFullYear()

    // Check if the input has exactly 8 characters
    if (customerInfo['birthday'].length !== 8) {
      shakeInput('birthday')
      showToast('warn', t('toast.invalidBirthday'))
      return false
    }

    // Extract day, month, and year parts
    const day = parseInt(customerInfo['birthday'].slice(0, 2), 10)
    const month = parseInt(customerInfo['birthday'].slice(2, 4), 10)
    const year = parseInt(customerInfo['birthday'].slice(4), 10)

    // Check if month is valid (1-12)
    if (month < 1 || month > 12) {
      shakeInput('birthday')
      showToast('warn', t('toast.invalidBirthday'))
      return false
    }

    // Check if year is in a reasonable range (e.g., 1900-2099)
    if (year < 1900 || year > currentYear) {
      console.log('yearyear', year)
      shakeInput('birthday')
      showToast('warn', t('toast.invalidBirthday'))
      return false
    }

    if (currentYear - year < 15) {
      shakeInput('birthday')
      showToast('warn', t('toast.invalidBirthdayRequired15'))
      return false
    }

    // Create a date object and check if the date is valid
    const date = new Date(year, month - 1, day)

    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day
  }

  const getRequiredFields = (): string[] => {
    return INPUT.concat({
      id: 3,
      title: 'register.birthday',
      placeholder: 'register.birthday',
      key: 'birthday',
      isRequired: true
    })
      .filter((field) => field.isRequired)
      .map((field) => field.key)
  }

  const shakeInput = (field: string) => {
    const inputElement = inputRefs.current[field]

    if (inputElement) {
      inputElement.classList.add('animate-shake')
      setTimeout(() => {
        inputElement.classList.remove('animate-shake')
      }, 500)
    }
  }

  const onNext = async () => {
    try {
      if (!(await isValidCustomer())) return
      if (!(await isValidFirstName())) return
      if (!(await isValidLastName())) return
      if (!(await isValidPhone())) return
      if (!(await isValidBirthday())) return

      let body = {
        ...customerInfo,
        birthday: convertYYYYMMDD(customerInfo.birthday)
      }

      onLoading(true)

      let resCreateCustomer = await createCustomer(body)
      if (!resCreateCustomer.is_error) {
        if (resCreateCustomer?.data?.errorCodes) {
          showToast('warn', t('toast.createCustomerFail'))
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1000)) // Remove the class after animation duration
          let resGetCustomer = await getCustomerProfile(resCreateCustomer?.data?.data)
          if (!resGetCustomer?.is_error) {
            let temp = {
              ...resGetCustomer?.data?.data,
              key: resGetCustomer?.data?.data?.id,
              name: resGetCustomer?.data?.data?.lastName + ' ' + resGetCustomer?.data?.data?.firstName
            }

            if (temp) {
              setPhone(customerInfo?.phone)
              setUserInfo(temp)
              _onClearCart()
              navigate('/shop')
            }
          }
        }
      }
      onLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  function formatDate(text: any) {
    // Extract day, month, and year based on the length of the input string
    const day = text.slice(0, 2) || 'dd'
    const month = text.slice(2, 4) || 'mm'
    const year = text.slice(4) || 'yyyy'

    // Pad month or year if they are not fully provided
    const formattedDay = day.padEnd(2, 'd') // Fills with "d" if day is incomplete
    const formattedMonth = month.padEnd(2, 'm') // Fills with "m" if month is incomplete
    const formattedYear = year.padEnd(4, 'y') // Fills with "y" if year is incomplete

    // Format as dd/mm/yyyy
    return `${formattedDay}/${formattedMonth}/${formattedYear}`
  }

  return (
    <div className='layout pb-40 gap-2 mt-2'>
      <Header title='register.label' />

      {INPUT?.map((val: any, index: number) => {
        return (
          <div onClick={() => setSelectedKey(val.key)} key={index} className='w-4/6 flex flex-col'>
            <p className='responsive-text-small-2'>
              {t(val.title).toUpperCase()} <span className='text-red-500'>{val.isRequired && '*'}</span>{' '}
            </p>

            <div
              ref={(node) => setRef(val.key as any, node)} // Assign ref to each div by key
              className={`w-full p-4 px-8 border-2 rounded-lg flex items-center ${selectedKey === val.key && 'border-blue-500 focus:ring-blue-500 focus:border-blue-500'}`}
            >
              {customerInfo[val.key] ? (
                <p className='responsive-text-small-3 break-all'>{customerInfo[val.key]} </p>
              ) : (
                <p className='text-[#9ca3af] responsive-text-small-3'> {t(val.placeholder)}</p>
              )}
            </div>
          </div>
        )
      })}

      <div onClick={() => setSelectedKey('birthday')} className='w-4/6 flex flex-col'>
        <p className='responsive-text-small-2'>
          {t('register.inputBirthday').toUpperCase()} <span className='text-red-500'>{'*'}</span>{' '}
        </p>

        <div
          ref={(node) => setRef('birthday' as any, node)} // Assign ref to each div by key
          className={`w-full p-4 px-8 border-2 rounded-lg flex items-center ${selectedKey === 'birthday' && 'border-blue-500 focus:ring-blue-500 focus:border-blue-500'}`}
        >
          {customerInfo['birthday'] ? (
            <p className='responsive-text-small-3 break-all'>{formatDate(customerInfo['birthday'])} </p>
          ) : (
            <p className='text-[#9ca3af] responsive-text-small-3'> {t('register.enterBirthday')}</p>
          )}
        </div>
      </div>

      <div className='text-center mt-8'>
        {selectedKey === 'phone' || selectedKey === 'birthday' ? (
          <div className='w-full flex flex-wrap gap-x-5 gap-y-2'>
            {KEY_BOARD_NUMBER_2?.map((val, index) => {
              if (val === 'br') {
                return <div key={index} className='w-full'></div> // Tạo dòng mới
              } else {
                return (
                  <button
                    onClick={() => onClick(val)}
                    key={index}
                    type='button'
                    className={`flex-grow ${isBold(val) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} py-8 rounded-[16px] btn-custom-input`}
                  >
                    {val === 'backspace' && <i className='material-icons responsive-text-small'>backspace</i>}
                    {val === 'reset' && <i className='material-icons responsive-text-small'>clear</i>}
                    {val !== 'backspace' && val !== 'reset' && <p className='responsive-text-small'>{val}</p>}
                  </button>
                )
              }
            })}
          </div>
        ) : (
          keyboard.map((key, index) => {
            if (key === 'br') {
              return <br key={index} />
            } else if (key === 'emty') {
              return (
                <button
                  type='button'
                  key={index}
                  className={` mx-1.5 
                   bg-white w-[200px] 
                  rounded-[16px] btn-custom-input mb-4 `}
                >
                  <div />
                </button>
              )
            } else if (key === 'textformat') {
              return (
                <button
                  type='button'
                  onClick={() => onKeyboardClick(key)}
                  key={index}
                  className={` 
                   bg-white w-[129px] border-2 border-[#E5E5E5]
                   h-[100px] rounded-[16px] btn-custom-input mb-4 `}
                >
                  <div className='flex items-center justify-center w-full h-full'>
                    <img className='w-[66px] h-[45px]' src={alpha} alt='alpha' />
                  </div>
                </button>
              )
            } else if (key === 'caps') {
              return (
                <button
                  type='button'
                  onClick={() => onKeyboardClick(key)}
                  key={index}
                  className={`mr-1.5
                   bg-white w-[120px] border-2 border-[#E5E5E5]
                   h-[100px] rounded-[16px] btn-custom-input mb-4 `}
                >
                  <div className='flex items-center justify-center w-full h-full'>
                    <img className='w-11 h-11' src={caps} alt='caps' />
                  </div>
                </button>
              )
            } else if (key === 'backspace') {
              return (
                <button
                  type='button'
                  onClick={() => onKeyboardClick(key)}
                  key={index}
                  className={` ml-1.5 
               bg-[#E2E7EF] w-[120px] border-2 border-[#E5E5E5]
               h-[100px] rounded-[16px] btn-custom-input mb-4 `}
                >
                  <div className='flex items-center justify-center w-full h-full'>
                    <img className='w-[58px] h-[38px]' src={remove} alt='remove' />
                  </div>
                </button>
              )
            } else if (key === '!?#') {
              return (
                <button
                  type='button'
                  onClick={() => onKeyboardClick(key)}
                  key={index}
                  className={`w-[129px] mx-1.5 
                   bg-[#E2E7EF]
                   h-[100px] rounded-[16px] btn-custom-input mb-4`}
                >
                  <p className='responsive-text-small'>{isCaps ? key.toUpperCase() : key.toLowerCase()}</p>
                </button>
              )
            } else {
              return (
                <button
                  type='button'
                  onClick={() => onKeyboardClick(key)}
                  key={index}
                  className={`${key === 'space' ? 'w-[240px]' : key === 'gmail.com' ? 'w-[180px]' : 'w-[82px]'} mx-1.5 
                    ${isKeyBold(key) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} 
                   h-[100px] rounded-[16px] btn-custom-input mb-4`}
                >
                  {key === 'reset' && <Icon title='clear' />}
                  {key === 'space' && <Icon title='space_bar' />}

                  {key !== 'reset' &&
                    key !== 'textformat' &&
                    key !== 'gmail.com' &&
                    key !== '.com' &&
                    key !== '.vn' &&
                    key !== 'space' && (
                      <p className='responsive-text-small'>{isCaps ? key.toUpperCase() : key.toLowerCase()}</p>
                    )}
                </button>
              )
            }
          })
        )}
      </div>

      <Footer onNext={onNext} />
    </div>
  )
}

//viewChildren
const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons responsive-text-small-2'>{title}</i>
}

export default Register

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const PHONE_REGEX = /^(0)(?!\s*$)(0|(\+84)|84)?(3[2-9]|5[125689]|7[06789]|8[1-9]|9[0-46-9])\d{7}$/
export const LAST_NAME_REGEX = /^(?=.{1,30}$).+$/;
export const FIRST_NAME_REGEX = /^(?=.{1,30}$).+$/;

// export const PHONE_REGEX = /^(?!\s*$)(0|(\+84)|84)?(3[2-9]|5[125689]|7[06789]|8[1-6789]|9[0-46-9])\d{7}$/
// export const PHONE_REGEX = /^(0|(\+84)|84)?(3[2-9]|5[125689]|7[06789]|8[1-6789]|9[0-46-9])\d{7}$/ RULE CỦA OMNI

export const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]):([0-9]{1,5})$/

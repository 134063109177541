import React from 'react'
import { useTranslation } from 'react-i18next'

interface IModal {
  title: string
  isModal: boolean
  onActionRight?: () => void
  onActionLeft?: () => void
  txtRight?: string
  txtLeft?: string
  bodyCustom?: any
  footerCustom?: any
}

const Modal: React.FC<IModal> = ({
  title,
  isModal,
  txtRight,
  txtLeft,
  bodyCustom,
  footerCustom,
  onActionRight,
  onActionLeft
}) => {
  return (
    <>
      {isModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[200] '>
            <div className='relative  w-5/6 '>
              {/*content*/}
              <div className='rounded-lg shadow-lg relative w-full bg-white p-8'>
                {bodyCustom ? bodyCustom() : <Body title={title} />}
                {footerCustom ? (
                  footerCustom()
                ) : (
                  <Footer
                    onActionRight={onActionRight}
                    onActionLeft={onActionLeft}
                    txtRight={txtRight}
                    txtLeft={txtLeft}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='opacity-60 fixed inset-0 z-[100] bg-black'></div>
        </>
      ) : null}
    </>
  )
}

//viewChildren
const Body: React.FC<any> = ({ title }) => {
  const { t } = useTranslation()

  const titleLines = t(title).split('\n')

  return (
    <p className='my-24 leading-relaxed text-center responsive-text-small'>
      {titleLines.map((line, index) => (
        <span key={index}>
          {line}
          {index < titleLines.length - 1 && <br />}
        </span>
      ))}
    </p>
  )
}

const Footer: React.FC<any> = ({ onActionLeft, onActionRight, txtRight, txtLeft }) => {
  const { t } = useTranslation()

  return (
    <div className={`flex items-center mt-8 gap-8 justify-center `}>
      {onActionLeft && (
        <button
          className='bg-white w-[360px]	h-[100px] rounded-lg border-2 border-[#0047BB] '
          onClick={() => onActionLeft(false)}
        >
          <p className={`content-accent700 responsive-text-small-2 `}>{t(txtLeft || 'Cancel')}</p>
        </button>
      )}

      {onActionRight && (
        <button className='background-accent700 w-[360px]	h-[100px] rounded-lg ' onClick={() => onActionRight(false)}>
          <p className={`text-white responsive-text-small-2 `}>{t(txtRight)}</p>
        </button>
      )}
    </div>
  )
}

export default Modal

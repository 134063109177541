import { APP_INFO } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { getAppInfoFromLocalStorage } from '@/src/utils'
import { receiptImages } from '@haravan/escpos'
import html2canvas from 'html2canvas'
import { showToast } from '../helper'

export const registerPrinter = async () => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  const device = await (navigator as any).usb.requestDevice({ filters: [] })

  const updatedAppInfo = {
    ...appInfo,
    printer: { vendorId: device.vendorId, productId: device.productId }
  }
  window.localStorage.setItem(APP_INFO, JSON.stringify(updatedAppInfo))

  return device
}

export const findPrinter = async (): Promise<any | null> => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  if (appInfo?.printer) {
    const { vendorId, productId } = appInfo?.printer
    const devices = await (navigator as any).usb.getDevices()
    return devices.find((device: any) => device.vendorId === vendorId && device.productId === productId)
  }

  const device = await registerPrinter()
  return device
}

export const printHTML = async (receiptElement: HTMLElement): Promise<void> => {
  try {
    if (!receiptElement) return

    const printer = await findPrinter()
    if (!printer) return

    const canvas = await html2canvas(receiptElement, {
      allowTaint: true,
      useCORS: true,
      scale: 1
    })

    // Split canvas into smaller chunks to handle long receipts
    const chunkedCanvasData = splitCanvasIntoChunks(canvas, 2000)

    // Create an array to store all image data from the chunks
    const imageDataArray: string[] = []

    // Loop through each chunk to get the image data
    for (let chunk of chunkedCanvasData) {
      const imageDataUrl = chunk.toDataURL('image/png') // Convert chunk to image URL
      const imageData = imageDataUrl.replace(/^data:image\/png;base64,/, '') // Remove base64 prefix
      imageDataArray.push(imageData) // Push image data to array
    }

    // Pass the imageDataArray into receiptImages to generate print data
    let printData = new Uint8Array(await receiptImages({ model: '80' }, imageDataArray))

    await connectAndPrint(printer, printData)
  } catch (err: any) {
    console.log(err)
    showToast('warn', `Print failed: ${err.toString()}`)
  }
}

// Hàm cắt canvas thành nhiều phần
const splitCanvasIntoChunks = (canvas: HTMLCanvasElement, chunkHeight: number): HTMLCanvasElement[] => {
  const chunks: HTMLCanvasElement[] = []
  const ctx = canvas.getContext('2d')
  const width = canvas.width
  const height = canvas.height

  let y = 0
  while (y < height) {
    const chunkCanvas = document.createElement('canvas')
    chunkCanvas.width = width
    chunkCanvas.height = Math.min(chunkHeight, height - y)
    const chunkCtx = chunkCanvas.getContext('2d')

    if (ctx && chunkCtx) {
      chunkCtx.drawImage(canvas, 0, y, width, chunkCanvas.height, 0, 0, width, chunkCanvas.height)
      chunks.push(chunkCanvas)
    }

    y += chunkHeight
  }

  return chunks
}

const connectAndPrint = async (printer: any, printData: Uint8Array): Promise<void> => {
  try {
    await printer.open()
    await printer.selectConfiguration(1)
    await printer.claimInterface(0)

    // Kiểm tra môi trường để chọn cấu hình
    if (import.meta.env.MODE === 'development') {
      await printer.transferOut(1, printData) // DEV
    } else {
      await printer.transferOut(import.meta.env.VITE_PRINTER_CONFIG as number, printData) // PROD
    }

    await printer.releaseInterface(0)
    await printer.close()
    console.log('Print successful')
  } catch (err: any) {
    console.log(err)
    showToast('warn', `Print failed: ${err.toString()}`)
  }
}

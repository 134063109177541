import { GET_API } from '../configApi'

const URL_SHOP_FE = import.meta.env.VITE_APP_DOMAIN_FE as string

export const getVerision = async (): Promise<any> => {
  const timeStamp = Date.now()

  return await GET_API({
    endpoint: `${URL_SHOP_FE}/version.json?timeStamp=${timeStamp}`,
    isIncludeAuthorization: false,
    isEndpointShop: false
  })
}
export const getLogin = async (): Promise<any> => {
  return await GET_API({ endpoint: 'admin-auth/getloginurl', isIncludeAuthorization: false })
}

export const getToken = async (code: string): Promise<any> => {
  return await GET_API({ endpoint: `admin-auth/getsessiontoken?code=${code}`, isIncludeAuthorization: false })
}

import {
  addPaymeCart,
  confirmFulfillmentOrder,
  confirmOrder,
  confirmPayme,
  confirmPaymeCart,
  payme,
  submitCart,
  updateCart
} from '@/src/apis'
import { banking, loading as loadingGif, qrCode } from '@/src/assets'
import { Header } from '@/src/components'
import { APP_INFO, CARRIER_MODEL, DEV_MODE, ID_CART, PAYMENT_TYPE_MODEL } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { formatMoney, isCheckEmptyBill, useLocalStorage } from '@/src/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
const FAKE_PHONE_PAYME = import.meta.env.VITE_FAKE_PHONE as string
const TIMEOUT_PAYME = 2 * 60 * 1000 // Giới hạn 2 phút
const TEST_PAYME = 'test123'
const TEST_PAYCODE_PAYME = 'ATM_POS'
const STATUS_PAYME = 'SUCCEEDED'
const MESSAGE_PAYME_TIMEOUT = 'Timeout: Checkout process took too long'

const PaymentMethodDetail: React.FC = () => {
  const { t } = useTranslation()
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  const [isModalErrorPayment, setIsModalErrorPayment] = useState<boolean>(false)
  const [count, setCount] = useState<any>(120) // 2 minutes
  const [tempOrder, setTempOrder] = useState<any>(null)
  const [isCreatedOrder, setIsCreatedOrder] = useState<boolean>(false)

  const isInitialMount = useRef(true)
  const isCancelled = useRef(false)

  // @ts-ignore
  const { cart, onLoading, onSetOrder, order, onClearCart, isLoading } = useApp()
  const { setUserInfo, setPhone, phone, onSetBill, bill, userInfo } = useUser()
  const { RFID, setRFID } = useRFID()

  const { methodId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      onCheckout()

      isInitialMount.current = false
    }
  }, [])

  useEffect(() => {
    if (isCreatedOrder && count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount: any) => prevCount - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [count, isCreatedOrder])

  useEffect(() => {
    if (count <= 0) {
      setIsModalErrorPayment(true)
    }
  }, [count])

  const onUpdateInfoCart = async () => {
    try {
      const arrEPCs = []

      for (let i = 0; i < RFID.length; i += 3) {
        const epcs = RFID.slice(i, i + 3).map((item: any) => item.tag)
        arrEPCs.push({
          key: `x-haravan-epcs-0${Math.floor(i / 3) + 1}`,
          value: epcs.toString()
        })
      }

      if (!arrEPCs) return

      const paymentPaymeId = cart?.paymentMethods?.find((e: any) => e.name.indexOf('Payme') > -1)?.id

      if (!paymentPaymeId) return

      //THEM THONG TIN USER
      const objUser = userInfo
        ? {
            // fullName: userInfo.name,
            customerId: userInfo.key
          }
        : {}

      let attributes = [...cart.attributes, ...arrEPCs]

      if (isCheckEmptyBill(bill)) {
        const billAttributes = Object.entries(bill)
          .map(([key, value]) => ({ key, value }))
          .filter((e) => e.value !== '')

        attributes = [...attributes, ...billAttributes]
      }
      const payload = {
        phone: phone || FAKE_PHONE_PAYME,
        paymentMethodId: paymentPaymeId,
        attributes,
        locationId: appInfo?.location?.id,
        fullName: userInfo?.name || (phone ? '' : 'WALK-IN'),
        ...objUser
      }

      await updateCart(cart.id, payload)
    } catch (err) {
      console.log(err)
    }
  }

  const onCheckout = async () => {
    try {
      let order = null
      let paymeResponse = null
      let orderResponse = null

      await (async () => {
        await onUpdateInfoCart()
        const cartResponse = await submitCart({
          locationId: appInfo?.location?.id,
          screenType: 1
        })

        if (cartResponse.is_error) return // Exit if there's an error submitting the cart
        setTempOrder(cartResponse?.data?.data?.order)
        setIsCreatedOrder(true) // Order created, now start timeout

        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error(MESSAGE_PAYME_TIMEOUT))
          }, TIMEOUT_PAYME)
        })

        await Promise.race([
          (async () => {
            if (devMode?.isDisablePayme) {
              // DISABLE PAYME => AUTO COMPLETE ORDER
              order = cartResponse?.data?.data?.order

              const payload = {
                order_id: order?.id,
                amount: order?.total,
                order_number: order?.orderName,
                payCode: TEST_PAYCODE_PAYME,
                username: TEST_PAYME
              }
              paymeResponse = await payme(payload)

              if (paymeResponse?.is_error) {
                setIsModalErrorPayment(true)
                onClearTransaction()
                return
              } else {
                // Confirm Payme
                const { partnerTransaction, amount } = paymeResponse.data.data
                const confirmPayload = {
                  partnerTransaction,
                  amount,
                  state: STATUS_PAYME,
                  payCode: paymeResponse.data.data.payCode
                }
                await confirmPayme(confirmPayload)
              }
            } else {
              // ENABLE PAYME => REQUIRED PAYME
              const paymentPaymeId = cartResponse?.data?.data?.paymentMethods?.find(
                (method: any) => method.name.indexOf('Payme') > -1
              )?.id

              paymeResponse = await addPaymeCart(
                cart.id,
                paymentPaymeId,
                cartResponse?.data?.data?.total,
                `${methodId}:${appInfo?.paymeCode}`
              )

              if (paymeResponse?.is_error || paymeResponse?.data?.hasError || paymeResponse?.data?.errors?.length > 0) {
                setIsModalErrorPayment(true)
                onClearTransaction()
                return
              }

              order = cartResponse?.data?.data?.order
            }

            // SAU KHI THANH TOÁN PAYME, CHỜ LẤY THÔNG TIN ĐƠN HÀNG => THÀNH CÔNG

            if (!paymeResponse?.is_error) {
              orderResponse = await confirmPaymeCart(order?.id, () => isCancelled.current)
              if (orderResponse) {
                onSetOrder(orderResponse)
                await confirmOrder(order?.id)
                await onFulfillment(orderResponse)
              }
            }
          })(),
          timeoutPromise
        ])

        // @ts-ignore
        if (paymeResponse?.is_error) return
        if (!orderResponse) return

        navigate('/payment-completed')
      })()
    } catch (error: any) {
      if (error.message != MESSAGE_PAYME_TIMEOUT) {
        setIsModalErrorPayment(true)
      }
    }
  }

  const onFulfillment = async (order: any) => {
    const fulfillPayload = {
      carrierShippingService: CARRIER_MODEL,
      order: {
        ...order,
        ...PAYMENT_TYPE_MODEL,
        orderProducts: null,

        locationId: appInfo?.location?.id || '',
        assignedLocationId: appInfo?.location?.id || '',
        totalWeight: order.totalWeight || 0
      },
      isSentEmail: true,
      listDetail: order?.orderProducts
    }
    await confirmFulfillmentOrder(order?.orderId, fulfillPayload)
  }

  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  const onClearTransaction = async () => {
    setIdCart(null)
    onSetOrder(null)
    onClearCart()
    setRFID([])
    setUserInfo(null)
    setPhone('')
    onSetBill()
  }

  const onCloseModalErr = () => {
    setIsModalErrorPayment(!isModalErrorPayment)
    navigate('/home')
    onClearTransaction()
  }

  return (
    <div className='layout pb-40'>
      <Header title='paymentMethod.payment' />

      {isCreatedOrder ? (
        <>
          {(() => {
            switch (methodId) {
              case 'VIETQR':
                return <QRCode total={total} tempOrder={tempOrder} />
              case 'ATM_POS':
              case 'CREDIT_POS':
                return <POS total={total} tempOrder={tempOrder} />
              default:
                return null
            }
          })()}

          <ModalPaymentFail isModal={isModalErrorPayment} onCloseModalErr={onCloseModalErr} tempOrder={tempOrder} />

          <p className='responsive-text-small text-center mx-20'>{t('paymentMethod.instruction')}</p>
          {count > 0 ? (
            <div className='flex gap-4'>
              <p className='responsive-text-small-2 mt-5'>{t('paymentMethod.waitingFor')}</p>
              <p className='responsive-text-small mt-5 font-bold animate-bounce content-accent700'>{count}</p>
              <p className='responsive-text-small-2 mt-5'>{t('paymentMethod.second')}</p>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <LoadingView />
      )}
    </div>
  )
}
//viewChildren
const QRCode: React.FC<any> = ({ total, tempOrder }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <div className='mt-40 flex flex-col justify-center items-center'>
      <p className='content-accent700 responsive-text-small-2 font-bold'>
        {t('invoice', { invoiceNo: tempOrder?.orderName })}
      </p>

      <p className='responsive-text-small'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text-small mt-20 text-center mx-20'>{t('paymentMethod.scanQrCodeInPosMachine')}</p>

      <img style={{ marginTop: '40px', height: '640px' }} src={qrCode} alt='img' />
    </div>
  )
}

const POS: React.FC<any> = ({ total, tempOrder }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <div className='mt-40 flex flex-col justify-center items-center'>
      <p className='content-accent700 responsive-text-small-2 font-bold'>
        {t('invoice', { invoiceNo: tempOrder?.orderName })}{' '}
      </p>

      <p className='responsive-text-small'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text-small mt-20 text-center mx-20'>
        {t('paymentMethod.usePosMachineToCompletePayment')}
      </p>

      <img style={{ marginTop: '40px', height: '640px', width: '960px' }} src={banking} alt='img' />
    </div>
  )
}

const ModalPaymentFail: React.FC<any> = ({ isModal, onCloseModalErr, tempOrder }) => {
  const { t } = useTranslation()

  const titleLines = t('paymentMethod.paymentFail').split('\n')

  return (
    <>
      {isModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[200] '>
            <div className='relative w-5/6 '>
              {/*content*/}
              <div className='rounded-lg shadow-lg relative w-full bg-white p-8'>
                <div className='my-24'>
                  <p className='text-center responsive-text-small content-accent700 font-bold'>
                    {t('invoice', { invoiceNo: tempOrder?.orderName })}
                  </p>
                  <p className='leading-relaxed text-center responsive-text-small'>
                    {titleLines.map((line, index) => (
                      <span key={index}>
                        {line}
                        {index < titleLines.length - 1 && <br />}
                      </span>
                    ))}
                  </p>
                </div>
                {/* FOOTER */}
                <div className={`flex items-center mt-8 gap-8 justify-center `}>
                  <button
                    className='bg-white w-[360px]	h-[100px] rounded-lg border-2 border-[#0047BB] '
                    onClick={onCloseModalErr}
                  >
                    <p className={`content-accent700 responsive-text-small-2 `}>{t('close')}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 fixed inset-0 z-[100] bg-black'></div>
        </>
      ) : null}
    </>
  )
}

const LoadingView: React.FC<any> = () => {
  return (
    <div className='flex w-full h-full fixed top-0 left-0 bg-white opacity-75 z-40 justify-center items-center'>
      <img className='w-20 h-20' src={loadingGif} alt='Loading' />
    </div>
  )
}

export default PaymentMethodDetail

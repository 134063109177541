import { getCustomer, getLoyaltyProfile } from '@/src/apis'
import { flagVn } from '@/src/assets'
import { Header, Modal } from '@/src/components'
import { APP_INFO, KEY_BOARD_NUMBER_2, PHONE_REGEX } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import './styles.css'
const FAKE_PHONE_PAYME = import.meta.env.VITE_FAKE_PHONE as string

interface IFooter {
  onSkip?: () => void
  onNext?: () => void
  nextTitle?: string
  isDisabledNext?: boolean
  isNext?: boolean
}

const LoginPhone: React.FC = () => {
  const { t } = useTranslation()

  const { setUserInfo, phone, setPhone } = useUser()
  const { onClearCart, onLoading } = useApp()
  const { setRFID, setIsReadingRFID } = useRFID()

  const [defaultUser, setDefaultUser] = useState<any>(null)
  const [isModalNotFound, setIsModalNotFound] = useState<boolean>(false)
  const [isModalInfo, setIsModalInfo] = useState<boolean>(false)
  const [loyalty, setLoyalty] = useState<any>(null)

  const divRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  useEffect(() => {
    onLoadData()
  }, [])

  const onLoadData = async () => {
    const res = await getCustomer(FAKE_PHONE_PAYME)
    if (!res?.false) {
      let temp = res?.data?.data?.data.find((item: any) => item.phone === FAKE_PHONE_PAYME)
      if (temp) setDefaultUser(temp)
    }
  }

  const isValidPhoneNumber = async () => {
    const isValid = PHONE_REGEX.test(phone)

    if (!isValid) {
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidPhoneNumber'))
    }
    return isValid
  }

  const _onClearCart = () => {
    onClearCart()
    setRFID([])
    setIsReadingRFID(false)
  }

  const onNext = async () => {
    if (!(await isValidPhoneNumber())) return
    setPhone(phone)
    onLoading(true)

    let res = await getCustomer(phone)

    if (!res?.is_error) {
      let temp = res?.data?.data?.data.find((item: any) => item.phone === phone)
      if (!temp) {
        setIsModalNotFound(true)
      } else {
        let resLoyalty = await getLoyaltyProfile({
          customer_id: temp?.key
        })
        if (!resLoyalty?.is_error) {
          setUserInfo(temp)
          setIsModalInfo(true)
          setLoyalty(resLoyalty?.data?.data)
        }
      }
    }
    onLoading(false)
  }

  const onSkip = async () => {
    if (defaultUser) {
      setUserInfo(defaultUser)
      _onClearCart()
      setPhone('')
      navigate('/shop')
    } else {
      onLoading(true)

      let res = await getCustomer(FAKE_PHONE_PAYME)

      if (!res?.is_error) {
        let temp = res?.data?.data?.data.find((item: any) => item.phone === FAKE_PHONE_PAYME)
        if (temp) setUserInfo(temp)
        _onClearCart()
        setPhone('')
        navigate('/shop')
      }
      onLoading(false)
    }
  }

  const onConfirmInfo = () => {
    _onClearCart()
    navigate('/shop')
  }

  const onRegister = () => {
    navigate(`/register/${phone}`)
  }

  return (
    <div className='layout pt-40'>
      <Header title='loginPhone.member' />

      <div className={'w-4/6 flex flex-col gap-4 mt-10'}>
        <p className={'responsive-text-small'}>{t('loginPhone.inputPhoneNumberForLoyalty')}</p>
        <KeyboardNumber phone={phone} setPhone={setPhone} divRef={divRef} />
      </div>
      <Footer onNext={onNext} onSkip={onSkip} nextTitle={'next'} />

      <Modal
        title={'shop.confirmOrder'}
        isModal={isModalInfo}
        txtLeft={'back'}
        onActionLeft={() => setIsModalInfo(!isModalInfo)}
        txtRight={'confirm'}
        onActionRight={onConfirmInfo}
        bodyCustom={() => <InfoLoyalty loyalty={loyalty} phone={phone} />}
      />

      <Modal
        title={'loginPhone.memberNotFound'}
        isModal={isModalNotFound}
        onActionLeft={() => setIsModalNotFound(!isModalNotFound)}
        footerCustom={() => (
          <FooterModalNotFound
            onActionRight={onRegister}
            onActionLeft={() => setIsModalNotFound(!isModalNotFound)}
            onSkip={onSkip}
          />
        )}
      />
    </div>
  )
}

//viewChildren

const KeyboardNumber: React.FC<any> = ({ phone, setPhone, divRef }) => {
  const { t } = useTranslation()

  const onClick = (val: string) => {
    if (val === 'backspace') {
      setPhone(phone.slice(0, -1))
    } else if (val === 'reset') {
      setPhone('')
    } else {
      if (phone?.length > 30) return
      setPhone(phone + val)
    }
  }

  const isBold = (val: string) => {
    let temp = false
    if (val === 'reset' || val === 'backspace') {
      temp = true
    }

    return temp
  }

  return (
    <>
      <div
        ref={divRef}
        className={
          'w-full py-9 px-8 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center mt-4 gap-4'
        }
      >
        <img style={{ height: '2.5rem' }} src={flagVn} alt={'flagVn'} />

        {phone ? (
          <p className={'responsive-text-small-2'}>{phone}</p>
        ) : (
          <p className={'text-[#9ca3af] responsive-text-small-2'}>{t('loginPhone.enterMobileNumber')}</p>
        )}
      </div>
      <div className='w-full flex flex-wrap gap-x-5 gap-y-2 mt-10'>
        {KEY_BOARD_NUMBER_2?.map((val, index) => {
          if (val === 'br') {
            return <div key={index} className='w-full'></div> // Tạo dòng mới
          } else {
            return (
              <button
                onClick={() => onClick(val)}
                key={index}
                type='button'
                className={`flex-grow ${isBold(val) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} py-8 rounded-[16px] btn-custom-input`}
              >
                {val === 'backspace' && <i className='material-icons responsive-text-small'>backspace</i>}
                {val === 'reset' && <i className='material-icons responsive-text-small'>clear</i>}
                {val !== 'backspace' && val !== 'reset' && <p className='responsive-text-small'>{val}</p>}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}
// @ts-ignore
const Footer: React.FC<IFooter> = ({ onNext, nextTitle, onSkip, isDisabledNext, isNext = true }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onBack = () => {
    navigate('/home')
  }

  return (
    <div className='z-50 fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-between'>
      <button onClick={onBack} className='button-prev footer'>
        <p className='content-accent700 responsive-text-small-2'>{t('back')}</p>
      </button>
      <button onClick={onSkip}>
        <p className='content-accent700 responsive-text-small-2 border-b-2 border-blue-500'>{t('skip')}</p>
      </button>
      <button onClick={onNext} className='button-next footer'>
        <p className='text-white responsive-text-small-2'>{t(nextTitle || 'confirm')}</p>
      </button>
    </div>
  )
}

const InfoLoyalty: React.FC<any> = ({ loyalty, phone }) => {
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const language = appInfo?.language || 'vi'

  const { t } = useTranslation()

  const maskedFullName = useMemo(() => {
    const lastName = loyalty?.lastName || ''
    const firstName = loyalty?.firstName || ''

    const temp = `${lastName} ${firstName}`.trim();

    const nameParts: any = temp.trim().split(' ')
    if (nameParts.length === 2) {
      return `${nameParts[0]} ${'*'.repeat(nameParts[1].length)}`
    } else if (nameParts.length > 2) {
      return `${nameParts[0]} ${nameParts
        .slice(1, -1)
        .map((part: any) => '*'.repeat(part.length))
        .join(' ')} ${nameParts[nameParts.length - 1]}`
    }
    return temp
  }, [loyalty])

  const maskedPhone = useMemo(() => {
    const visibleDigits = 4
    const maskedPart = '*'.repeat(phone?.length - visibleDigits)
    const visiblePart = phone?.slice(-visibleDigits)
    return maskedPart + visiblePart
  }, [phone])

  const width_1 = (language: any) => {
    return language === 'en' ? 'w-[28%]' : 'w-[48%]'
  }

  const width_2 = (language: any) => {
    return language === 'en' ? 'w-[72%]' : 'w-[52%]'
  }

  return (
    <>
      <p className='responsive-text-small'>{t('loginPhone.memberInfo')}</p>
      {!loyalty ? (
        <>
          <div className='border border-[#E5E5E5] rounded-lg py-20'>
            <p className='responsive-text-small-3 text-center italic'>Chưa có thông tin hạng thành viên</p>
          </div>
        </>
      ) : (
        <div className='border border-[#E5E5E5] rounded-lg p-4'>
          {/*  */}
          <div className='flex'>
            <div className={`${width_1(language)}`}>
              <p className='responsive-text-small-3'>{t('loginPhone.memberFullName')}:</p>
            </div>
            <div className={`${width_2(language)}`}>
              <p className='responsive-text-small-3'>{maskedFullName} </p>
            </div>
          </div>
          {/*  */}
          <div className='flex'>
            <div className={`${width_1(language)}`}>
              <p className='responsive-text-small-3'>{t('loginPhone.memberPhone')}:</p>
            </div>
            <div className={`${width_2(language)}`}>
              <p className='responsive-text-small-3'>{maskedPhone} </p>
            </div>
          </div>
          {/*  */}
          <div className='flex'>
            <div className={`${width_1(language)}`}>
              <p className='responsive-text-small-3'>{t('loginPhone.memberCode')}:</p>
            </div>
            <div className={`${width_2(language)}`}>
              <p className='responsive-text-small-3'>{loyalty?.code} </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FooterModalNotFound: React.FC<any> = ({ onActionRight, onActionLeft, onSkip }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`flex items-center mt-8 gap-8 justify-center px-20`}>
        <button
          className='bg-white w-[360px]	h-[100px] rounded-lg border-2 border-[#0047BB] '
          onClick={() => onActionLeft(false)}
        >
          <p className={`content-accent700 responsive-text-small-2 `}>{t('tryAgain')}</p>
        </button>

        <button className='background-accent700 w-[360px]	h-[100px] rounded-lg ' onClick={() => onActionRight(false)}>
          <p className={`text-white responsive-text-small-2 `}>{t('register.label')}</p>
        </button>
      </div>

      <div className='flex items-center justify-center mt-8'>
        <button onClick={onSkip}>
          <p className='content-accent700 responsive-text-small-2 border-b-2 border-blue-500'>{t('skip')}</p>
        </button>
      </div>
    </>
  )
}

export default LoginPhone

import { flagUk, flagVn } from '@/src/assets'

export const TOKEN = import.meta.env.VITE_APP_SID as string
export const APP_INFO = 'APP_INFO'
export const ID_CART = 'ID_CART'
export const USER_PHONE = 'USER_PHONE'
export const LANG = 'APP_LANG'
export const CHANNEL = 'self-checkout'
export const DEV_MODE = 'DEV_MODE'
export const FAKE_PHONE_PAYME = '0938128792'
export const BRAND = 'URBAN REVIVO'
export const INACTIVITY_TIMEOUT = 300000 // 5 minutes
export const PIN_SETTING = '201024' // MÃ PIN ĐĂNG NHẬP VÀO MÀN HÌNH SETTINGS
export const LANGUAGES = [
  {
    id: 0,
    lang: 'vi',
    title: 'Tiếng Việt',
    icon: flagVn
  },
  {
    id: 1,
    lang: 'en',
    title: 'English',
    icon: flagUk
  }
]

export const WHITE_LIST_DEV_MODE = [
  '200000014360', // https://enterprise-demo.sku.vn/
  '1000113771', // https://test-maison.sku.vn/
  '1000356698' //https://uat-maison.myharavan.com/
]

export const MAISON_UAT_ORG_ID = '1000356698'

export const CART = {
  lineItems: [
    {
      pricingListId: null,
      sku: '100195960002_CS198',
      barcode: '196395350810',
      id: 3598157,
      seq: null,
      variantId: 1123338764,
      variantType: 0,
      productId: 1054735399,
      productTitle: 'COACH - Áo khoác denim nam cổ bẻ tay dài thời thượng 1',
      variantTitle: 'Đen / M / CS198',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/blk_cs198_1_fbe95079fb5d4e128f5ee2a5d4319406_1786418caf2348d8bee90eb5cd53eae1.jpg',
      priceOriginal: 13000000,
      price: 13000000,
      linePrice: 13000000,
      lineDiscount: 0,
      quantity: 4,
      weight: 800,
      lineWeight: 3200,
      lineTax: 3851851.8519,
      lineAmount: 52000000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100185345003_CM545',
      barcode: '196395099597',
      id: 4694679,
      seq: null,
      variantId: 1123338757,
      variantType: 0,
      productId: 1054735397,
      productTitle: 'COACH -  Túi xách nữ phom hình thang thời trang',
      variantTitle: 'Be / ONE / CM545',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/b4iy_cm545_1_701d9ae1eb414bc7b133d91e4a222247_9e52de38a75d408baf064adef9772935.jpg',
      priceOriginal: 13000000,
      price: 13000000,
      linePrice: 13000000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1650,
      lineWeight: 1650,
      lineTax: 962962.963,
      lineAmount: 13000000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196462001_5041024206',
      barcode: '50410242060011',
      id: 4694721,
      seq: null,
      variantId: 1123338718,
      variantType: 0,
      productId: 1054735383,
      productTitle: 'WEEKEND MAX MARA - Áo khoác măng tô cổ bẻ tay dài Alpino',
      variantTitle: 'Be / 2XS / 5041024206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5041024206_1_c0c75eb1d0334c479381ba9e6fd5c337_a879328d927a4d5fbf8ada15e17326d0.jpg',
      priceOriginal: 14990000,
      price: 14990000,
      linePrice: 14990000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1950,
      lineWeight: 1950,
      lineTax: 1110370.3704,
      lineAmount: 14990000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196487001_5221094206',
      barcode: '52210942060011',
      id: 4696274,
      seq: null,
      variantId: 1123338736,
      variantType: 0,
      productId: 1054735391,
      productTitle: 'WEEKEND MAX MARA - Đầm midi cổ tròn tay dài phối hoạ tiết Ghiotto',
      variantTitle: 'Xanh dương / 34 / 5221094206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5221094206_1_df005b5686664177b52501594411de9c_0c81e540438249f2a77edee077aa8fc7.jpg',
      priceOriginal: 14490000,
      price: 14490000,
      linePrice: 14490000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1500,
      lineWeight: 1500,
      lineTax: 1073333.3333,
      lineAmount: 14490000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    },
    {
      pricingListId: null,
      sku: '100196487004_5221094206',
      barcode: '52210942060014',
      id: 4696299,
      seq: null,
      variantId: 1123338739,
      variantType: 0,
      productId: 1054735391,
      productTitle: 'WEEKEND MAX MARA - Đầm midi cổ tròn tay dài phối hoạ tiết Ghiotto',
      variantTitle: 'Xanh dương / 40 / 5221094206',
      imageUrl:
        'https://product.hstatic.net/200000396151/product/01_5221094206_1_df005b5686664177b52501594411de9c_0c81e540438249f2a77edee077aa8fc7.jpg',
      priceOriginal: 14490000,
      price: 14490000,
      linePrice: 14490000,
      lineDiscount: 0,
      quantity: 1,
      weight: 1500,
      lineWeight: 1500,
      lineTax: 1073333.3333,
      lineAmount: 14490000,
      availableQuantity: null,
      lotSupport: false,
      variantUnitId: null,
      unitRatio: 1,
      unit: null,
      requiresShipping: true,
      notAllowPromotion: false,
      discountAllocations: [],
      attributes: [],
      components: null
    }
  ],
  pricingListId: null,
  pricingListName: null,
  customerId: null,
  saleUserId: null,
  createdUserId: null,
  webUrl:
    'https://checkouts.haravan.app/checkouts/api/200000396151/108e66ee5444471d0055f3802f62b844/auth?k=4fc9d1ba338bef610b675e5a079746d6',
  hasError: false,
  id: '108e66ee5444471d0055f3802f62b844',
  phone: null,
  fullName: null,
  shippingAddress: null,
  shippingCountryId: null,
  shippingProvinceId: null,
  shippingDistrictId: null,
  shippingWardId: null,
  shippingMethodId: null,
  shippingMethodName: null,
  paymentMethodName: null,
  paymentMethodId: null,
  payDepositOnly: false,
  depositRequired: false,
  eInvoiceRequest: true,
  eInvoiceInfo: null,
  utmParameters: null,
  depositAmount: 0,
  depositCODAmount: 0,
  note: null,
  requiresShipping: true,
  createdAt: '2024-06-26T02:52:12.495286Z',
  updatedAt: '2024-06-26T02:52:12.495287Z',
  completedAt: null,
  errors: [],
  warnings: [],
  userErrors: [],
  ready: true,
  shippingMethods: [],
  paymentMethods: [
    {
      id: 1003298983,
      name: 'Thanh toán khi giao hàng (COD)',
      desc: null,
      sysTypeId: 1,
      online: false
    },
    {
      id: 1003540576,
      name: 'Thanh toán công nợ',
      desc: null,
      sysTypeId: 4,
      online: false
    },
    {
      id: 1003628176,
      name: 'Thanh toán online qua ví MoMo',
      desc: null,
      sysTypeId: 21,
      online: true
    },
    {
      id: 1003630076,
      name: 'Thanh toán online qua ứng dụng ZaloPay bằng QRCode',
      desc: null,
      sysTypeId: 22,
      online: true
    },
    {
      id: 1003630078,
      name: 'Thanh toán online qua cổng ZaloPay bằng thẻ ATM nội địa',
      desc: null,
      sysTypeId: 23,
      online: true
    },
    {
      id: 1003742176,
      name: 'Thanh toán online qua cổng VNPay (ATM/Visa/MasterCard/JCB/QR Pay trên Mobile Banking)',
      desc: null,
      sysTypeId: 8,
      online: true
    },
    {
      id: 1003845601,
      name: 'Chuyển khoản qua ngân hàng',
      desc: null,
      sysTypeId: 2,
      online: false
    }
  ],
  discountAllocations: [],
  attributes: [],
  discount: 0,
  locationId: null,
  locationName: null,
  customerMembership: null,
  constraint: null,
  subTotalBeforeTax: 100898148.1481,
  subTotalTax: 8071851.8519,
  subTotal: 108970000,
  shippingBeforeTax: 100898148.1481,
  shippingTax: 0,
  shipping: 0,
  totalTaxIncluded: 8071851.8519,
  totalTaxNotIncluded: 0,
  total: 108970000,
  order: null,
  channel: 'haravan_draft_order',
  disallowLoyaltyProgram: false,
  sendNotify: false,
  sendReceipt: false,
  nextActions: null
}

export const RFID = {
  rawData:
    'Get TagList\n 1 30340BFC184F94C000000019 912.75 -59\r\n 1 30340BFC184F94C00000001E 912.75 -59\r\n 1 30340BFC184F94C00000001C 912.75 -47\r\n 1 30340BFC184F94C00000001D 912.75 -43\r\n 1 30340BFC184F94C000000007 912.75 -58\r\n 1 30340BFC184F94C00000000B 920.75 -55\r\n 1 30340BFC184F94C000000008 912.75 -51\r\n 1 30340BFC184F94C000000002 920.75 -50\r\n 1 30340BFC184F94C00000000A 909.75 -54\r\n 1 30340BFC184F94C000000004 909.75 -57\r\nTotal 10\r\nmpos> ',
  errorMessage: '',
  createdAt: '2024-08-05T08:45:34.991Z',
  usbVendorId: 1027,
  usbProductId: 24597,
  listEPC: [
    '30340BFC184F94C000000019',
    '30340BFC184F94C00000001E',
    '30340BFC184F94C00000001C',
    '30340BFC184F94C00000001D',
    '30340BFC184F94C000000007',
    '30340BFC184F94C00000000B',
    '30340BFC184F94C000000008',
    '30340BFC184F94C000000002',
    '30340BFC184F94C00000000A',
    '30340BFC184F94C000000004'
  ],
  listBarcode: [
    {
      barcode: '196395246779',
      qty: 2,
      epcs: ['30340BFC184F94C000000019', '30340BFC184F94C00000001E']
    },
    {
      barcode: '196395246878',
      qty: 3,
      epcs: ['30340BFC184F94C00000001C', '30340BFC184F94C00000001D', '30340BFC184F94C000000007']
    },
    {
      barcode: '196395308620',
      qty: 4,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C000000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A'
      ]
    },
    {
      barcode: '52610143060012',
      qty: 8,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C000000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A',
        '30340BFC184F94C00000000C',
        '30340BFC184F94C00000000D',
        '30340BFC184F94C00000000F',
        '30340BFC184F94C00000000I'
      ]
    },
    {
      barcode: '193314167504',
      qty: 8,
      epcs: [
        '30342BFC184F94C000000004',
        '30340BFC184F94C000000007',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00000000A',
        '30340BFC184F94C00020000C',
        '30340B2C184F94C00004000D',
        '30340BFC184F95C00000000F',
        '303404FC184F94C06000000I'
      ]
    },
    {
      barcode: '197627200606',
      qty: 4,
      epcs: [
        '30340BFC184F94C00000000B',
        '30340BFC184F94C005000008',
        '30340BFC184F94C000000002',
        '30340BFC184F94C00003000A'
      ]
    },
    {
      barcode: '197627797182',
      qty: 1,
      epcs: ['30340BFC184F94C60000000B']
    }
  ]
}

export const RFID_SAMPLE_MAISON = [
  {
    tag: 'ac7965480c40119500a0169240018b46',
    barcode: 'UWH930002X01XS'
  },
  // {
  //   tag: 'ac7a05490c41181500a85c9240010746',
  //   barcode: 'UWG940300HDA0L'
  // },
  {
    tag: 'ac7a05490c41181500a85c924001074644',
    barcode: 'UWH840121WC1XS'
  },
  // {
  //   tag: 'ac7a05490c41181500a85c924001074622',
  //   barcode: 'UAMB40093GG199'
  // },
  {
    tag: 'ac7a04490c0000a100185c924001794f',
    barcode: 'UWH930002X01XS'
  },
  // {
  //   tag: 'ac7a04420c4018d500a01c9240018442',
  //   barcode: 'UWH232033L0A0S' // //
  // },
  // {
  //   tag: 'ac7a0402100041e000a01c924001844f',
  //   barcode: 'UWG240087W0A0S' // //
  // },
  {
    tag: 'ac7a04450c000061001855924002124f',
    barcode: 'UWH530001X01XL'
  }
  // {
  //   tag: 'ac7a04050c00102008a85c924000254f',
  //   barcode: 'UWG530020W2AXS' // //
  // },
  // {
  //   tag: 'ac7963c41000301014a01c924000134f',
  //   barcode: 'UMF440060G5A0S' // //
  // },
  // {
  //   tag: 'ac7a05480c41101508a0169240021e46',
  //   barcode: 'UWL832120L2A0M' // //
  // },
  // {
  //   tag: 'ac7a04090c4030d908a01c924000e846',
  //   barcode: 'UWG932063P2A0S' // //
  // },
  // {
  //   tag: 'ac7965480c40119500a0169240018b46',
  //   barcode: 'UML832026L0A0M' // //
  // },
  // {
  //   tag: 'ac7963c61000015c5c1089924000ea42',
  //   barcode: 'UMF640005SN129' // //
  // },
  // {
  //   tag: 'ac7a05490c41181500a85c9240010746',
  //   barcode: 'UWL932130L0AXS'
  // }
  // ,
  // {
  //   tag: 'ac7a05490c41181500a85c9240010746223122',
  //   barcode: 'UWL832120L2A0M'
  // }
]

export const RFID_SAMPLE_MAISON_2 = [
  // {
  //   tag: 'ac7965480c40119500a0169240018b46123',
  //   barcode: 'UWU140033LQ1XS'
  // },
  // {
  //   tag: 'ac7965480c40119500a0169240018b46',
  //   barcode: 'UMV440121GF10L'
  // },
  // {
  //   tag: 'ac7a05490c41181500a85c9240010746',
  //   barcode: 'UWM640001W0A0S'
  // },
  // {
  //   tag: 'ac7a05490c41181500a85c924001074644',
  //   barcode: 'UWM640001W0A0S'
  // },
  // {
  //   tag: 'ac7a05490c41181500a85c924001074622',
  //   barcode: 'UYV540003K1A0L'
  // },
  // {
  //   tag: 'ac7a05490c41181500a85c9240010746223122',
  //   barcode: 'UYV540003K1A0L'
  // }
]

export const ORDER = {
  orderShipments: [
    {
      id: 1209923158,
      createdDate: '2024-10-15T09:57:44.548Z',
      codAmount: 0,
      tracking_Company: 'Khác',
      carrierStatus: 4,
      carrierCODStatus: 1,
      shipmentProducts: [
        {
          orderProductId: 1849337625,
          quantity: 1,
          productName: 'URBAN REVIVO - Quần jogger nam lưng thun rút dây Drawstring',
          price: 1099000,
          productId: 1057078157,
          variantValue: null,
          isDeleted: false,
          sku: '100200300006',
          totalPrice: 0,
          tracking_Number: null,
          tracking_Url: null,
          imageUrl:
            'https://product.hstatic.net/1000356698/product/b6a_umy640003_3_a6bd961eb2de4f498a611ee7066fe542_fbd8dc88dddc468981def26d60137fc4.jpg',
          variantId: null
        },
        {
          orderProductId: 1849337626,
          quantity: 1,
          productName: 'URBAN REVIVO - Balo nam phom chữ nhật Tie Dye Printed',
          price: 1500,
          productId: 1057077551,
          variantValue: null,
          isDeleted: false,
          sku: '100200138001',
          totalPrice: 0,
          tracking_Number: null,
          tracking_Url: null,
          imageUrl:
            'https://product.hstatic.net/1000356698/product/q0a_uamb40107_1_1fa2648eded2455da9cd15dbeac8f1de_029f5ea70acb498e805aca9394b369b4.jpg',
          variantId: null
        },
        {
          orderProductId: 1849337627,
          quantity: 1,
          productName: 'URBAN REVIVO - Áo thun nam cổ tròn tay ngắn phối hình Printed',
          price: 569000,
          productId: 1057077564,
          variantValue: null,
          isDeleted: false,
          sku: '100200295012',
          totalPrice: 0,
          tracking_Number: null,
          tracking_Url: null,
          imageUrl:
            'https://product.hstatic.net/1000356698/product/ge1_umy440001_1_65062919e17a475f8577cc1b2fab3c89_eb83a3a9734b4e44853601ccb31f1ec3.jpg',
          variantId: null
        }
      ],
      shippingAddress: null,
      shippingCountry: 241,
      provinceId: null,
      districtId: null,
      wardId: null,
      shippingPhone: null,
      shippingFee: 0,
      realAmount: 0,
      isArchived: false,
      archivedDate: null,
      isAuto: false,
      shippingNote: null,
      locationId: 1706750,
      locationName: 'URBAN REVIVO Vincom Đồng Khởi',
      trackingUrlNumber: null,
      trackingNumber: null,
      carrierTrackingId: null,
      crossCheckStatus: 1,
      carrierServiceName: '',
      totalWeight: 5754,
      carrierDiscountCode: '',
      packageWidth: 0,
      packageLength: 0,
      packageHeight: 0,
      shipmentMethod: 0,
      shipmentMethodDisplay: null,
      carrierCode: null,
      shippingCity: null,
      shippingZipCode: null,
      carrierId: null,
      sortCode: null,
      shipmentNoteAttribute: [],
      firstName: null,
      lastName: null,
      fulfilledStatus: 2,
      carrierPackageId: null
    }
  ],
  sortCode: null,
  code: null,
  orderId: 1640641032,
  orderNumber: '830101000153',
  createdDate: '2024-10-15T09:57:42.992Z',
  paymentStatusId: 2,
  fulFillMentStatusId: 1,
  fulFillMentStatusName: 'Đã hoàn thành',
  orderTotal: 1669500,
  vat: 0,
  orderNotes: null,
  archivedDate: null,
  unArchivedDate: null,
  customerId: 1018000237,
  customerName: ' ',
  paymentMethodName: null,
  amountRefuned: 0,
  paymentMethodId: null,
  amountPaid: 1669500,
  orderStatusId: 0,
  customerEmail: null,
  cancelReasonOrderId: null,
  shipFullName: '',
  billFullName: '',
  orderShippingFee: 0,
  subTotal: 1669500,
  amountToPaid: 0,
  refunded: 1669500,
  totalTransactions: 2,
  tags: '',
  discountId: null,
  discountCode: null,
  noteAttributes: [
    {
      id: 1357369979,
      name: 'x-haravan-device',
      value: 'TEST01'
    }
  ],
  orderProducts: [
    {
      id: 1849337625,
      isFulfill: true,
      productName: 'URBAN REVIVO - Quần jogger nam lưng thun rút dây Drawstring',
      quantity: 1,
      productPrice: 1099000,
      totalPrice: 1099000,
      versionNo: null,
      productId: 1057078157,
      orderId: 1640641032,
      totalFulfill: 1,
      quantityNotFulfilled: 0,
      trackingNumber: null,
      trackingCompany: null,
      carrierTrackingId: null,
      carrierId: null,
      quantityFulfilled: 0,
      carrierURL: null,
      carrierName: null,
      variantValue: 'B6A / XS / UMY640003',
      sku: '100200300006',
      fulfillStatusId: 1,
      weight: 1500,
      productTypeName: 'Quần jogger',
      productVendorName: 'URBAN REVIVO',
      barcode: 'UWL832120L2A0M',
      customerEmail: null,
      customerName: null,
      isDeleted: false,
      imageUrl:
        'https://product.hstatic.net/1000356698/product/b6a_umy640003_3_a6bd961eb2de4f498a611ee7066fe542_fbd8dc88dddc468981def26d60137fc4.jpg',
      productVariantsId: 1130576034,
      discountPrice: 0,
      inventoryText: '(còn 1917.0000 trong kho URBAN REVIVO Vincom Đồng Khởi)',
      inventoryLocationQuantity: '1917.0000',
      inventoryLocationName: 'URBAN REVIVO Vincom Đồng Khởi',
      isShipping: true,
      fulfillServicesList: [],
      properties: [],
      restockQuantity: 0,
      returnedQuantity: 0,
      totalQtyCanRestock: 0,
      totalQtyCanReturn: 1,
      restockableQuantity: 0,
      returnableQuantity: 1,
      originalPrice: 1099000,
      showOriginalPrice: false,
      discountPromotion: 0,
      discountAmount: 0,
      discountDescription: null,
      discountPerOrder: 0,
      discountLineCode: null,
      discountLineCodeValue: 0,
      isOrderOldWay: false,
      isTrackingInventory: true,
      notAllowPromotion: false,
      variantUnitId: null,
      ratio: 1,
      unit: null,
      unitIsDeleted: false,
      units: null,
      lots: null,
      type: 0,
      lotSupport: false,
      pricingListId: null,
      pricingListName: null,
      totalTax: 81407.4074,
      linePrice: 1099000,
      lineAmount: 1099000,
      lineDiscount: 0,
      discountAllocations: []
    },
    {
      id: 1849337626,
      isFulfill: true,
      productName: 'URBAN REVIVO - Balo nam phom chữ nhật Tie Dye Printed',
      quantity: 1,
      productPrice: 1500,
      totalPrice: 1500,
      versionNo: null,
      productId: 1057077551,
      orderId: 1640641032,
      totalFulfill: 1,
      quantityNotFulfilled: 0,
      trackingNumber: null,
      trackingCompany: null,
      carrierTrackingId: null,
      carrierId: null,
      quantityFulfilled: 0,
      carrierURL: null,
      carrierName: null,
      variantValue: 'Q0A / 99 / UAMB40107',
      sku: '100200138001',
      fulfillStatusId: 1,
      weight: 3054,
      productTypeName: 'Balo',
      productVendorName: 'URBAN REVIVO',
      barcode: 'UWJ430025B4A0M',
      customerEmail: null,
      customerName: null,
      isDeleted: false,
      imageUrl:
        'https://product.hstatic.net/1000356698/product/q0a_uamb40107_1_1fa2648eded2455da9cd15dbeac8f1de_029f5ea70acb498e805aca9394b369b4.jpg',
      productVariantsId: 1130574612,
      discountPrice: 0,
      inventoryText: '(còn 9967.0000 trong kho URBAN REVIVO Vincom Đồng Khởi)',
      inventoryLocationQuantity: '9967.0000',
      inventoryLocationName: 'URBAN REVIVO Vincom Đồng Khởi',
      isShipping: true,
      fulfillServicesList: [],
      properties: [
        {
          id: 1134934529,
          orderId: 1640641032,
          name: 'Khuyến mãi',
          value: '1054815891 - Bản sao tạo từ Bản sao tạo từ TEST0810',
          orderProductId: 1849337626
        }
      ],
      restockQuantity: 0,
      returnedQuantity: 0,
      totalQtyCanRestock: 0,
      totalQtyCanReturn: 1,
      restockableQuantity: 0,
      returnableQuantity: 1,
      originalPrice: 2000,
      showOriginalPrice: true,
      discountPromotion: 500,
      discountAmount: 0,
      discountDescription: null,
      discountPerOrder: 0,
      discountLineCode: null,
      discountLineCodeValue: 0,
      isOrderOldWay: false,
      isTrackingInventory: true,
      notAllowPromotion: false,
      variantUnitId: null,
      ratio: 1,
      unit: null,
      unitIsDeleted: false,
      units: null,
      lots: null,
      type: 0,
      lotSupport: false,
      pricingListId: null,
      pricingListName: null,
      totalTax: 111.1111,
      linePrice: 1500,
      lineAmount: 1500,
      lineDiscount: 500,
      discountAllocations: [
        {
          discountId: 1054815891,
          code: 'Bản sao tạo từ Bản sao tạo từ TEST0810',
          amount: 500,
          type: 'LINEITEM'
        }
      ]
    },
    {
      id: 1849337627,
      isFulfill: true,
      productName: 'URBAN REVIVO - Áo thun nam cổ tròn tay ngắn phối hình Printed',
      quantity: 1,
      productPrice: 569000,
      totalPrice: 569000,
      versionNo: null,
      productId: 1057077564,
      orderId: 1640641032,
      totalFulfill: 1,
      quantityNotFulfilled: 0,
      trackingNumber: null,
      trackingCompany: null,
      carrierTrackingId: null,
      carrierId: null,
      quantityFulfilled: 0,
      carrierURL: null,
      carrierName: null,
      variantValue: 'GE1 / M / UMY440001',
      sku: '100200295012',
      fulfillStatusId: 1,
      weight: 1200,
      productTypeName: 'Áo thun',
      productVendorName: 'URBAN REVIVO',
      barcode: 'UMY440001GE10M',
      customerEmail: null,
      customerName: null,
      isDeleted: false,
      imageUrl:
        'https://product.hstatic.net/1000356698/product/ge1_umy440001_1_65062919e17a475f8577cc1b2fab3c89_eb83a3a9734b4e44853601ccb31f1ec3.jpg',
      productVariantsId: 1130574644,
      discountPrice: 0,
      inventoryText: '(còn 987.0000 trong kho URBAN REVIVO Vincom Đồng Khởi)',
      inventoryLocationQuantity: '987.0000',
      inventoryLocationName: 'URBAN REVIVO Vincom Đồng Khởi',
      isShipping: true,
      fulfillServicesList: [],
      properties: [],
      restockQuantity: 0,
      returnedQuantity: 0,
      totalQtyCanRestock: 0,
      totalQtyCanReturn: 1,
      restockableQuantity: 0,
      returnableQuantity: 1,
      originalPrice: 569000,
      showOriginalPrice: false,
      discountPromotion: 0,
      discountAmount: 0,
      discountDescription: null,
      discountPerOrder: 0,
      discountLineCode: null,
      discountLineCodeValue: 0,
      isOrderOldWay: false,
      isTrackingInventory: true,
      notAllowPromotion: false,
      variantUnitId: null,
      ratio: 1,
      unit: null,
      unitIsDeleted: false,
      units: null,
      lots: null,
      type: 0,
      lotSupport: false,
      pricingListId: null,
      pricingListName: null,
      totalTax: 42148.1481,
      linePrice: 569000,
      lineAmount: 569000,
      lineDiscount: 0,
      discountAllocations: []
    }
  ],
  transactions: [
    {
      id: 0,
      transactionName: 'Chờ xử lý',
      paymentMethodName: null,
      amountTransaction: 1669500,
      createdDate: '0001-01-01T00:00:00',
      gateway: null,
      externalTransactionId: null,
      type: 'pending',
      paymentInstructionTypeId: 0,
      paymentInstructionId: null
    },
    {
      id: 0,
      transactionName: 'Xác nhận thanh toán',
      paymentMethodName: 'Thanh toán bằng thẻ ATM qua cổng Payme',
      amountTransaction: 1669500,
      createdDate: '0001-01-01T00:00:00',
      gateway: 'Thanh toán bằng thẻ ATM qua cổng Payme',
      externalTransactionId: null,
      type: 'paid',
      paymentInstructionTypeId: 0,
      paymentInstructionId: null
    }
  ],
  isUpdateProfile: true,
  isArchived: false,
  isShipping: true,
  isShowFulfill: false,
  isShowPendingFulfill: false,
  isShowAllFulfill: true,
  isShowCapturePaid: false,
  isShowPaymentReceived: true,
  isShowRefund: true,
  isTrackingInventory: true,
  isAmountRefund: true,
  isCancel: false,
  cancelDate: null,
  orderContactBuyerMail: null,
  customerIp: null,
  isFraudulent: false,
  currencyId: 4,
  currencyCode: 'VND',
  orderDate: '2024-10-15T09:57:43.095Z',
  totalWeight: 5754,
  isDeleted: false,
  totalPaid: 1669500,
  discountAmount: 0,
  isGroupAddress: true,
  cancelNote: null,
  isRestockItem: true,
  isReturnItem: false,
  refundNote: null,
  totalRefunded: 1669500,
  symbol: '₫',
  codAmount: 0,
  maxCODAmount: 0,
  referringSite: null,
  districtId: null,
  districtName: null,
  wardId: null,
  wardName: null,
  landingSite: null,
  landingSiteRef: null,
  source: 'pos',
  sourceName: 'pos',
  orderPrinterResult: null,
  totalDiscounts: 0,
  draftOrderId: null,
  eInvoiceInfo: null,
  draftDeleted: false,
  draftNumber: null,
  confirmedDate: '2024-10-15T09:57:44Z',
  confirmedUser: 200002479813,
  confirmedUserName: null,
  isConfirmed: true,
  shippingNote: null,
  locationId: 1706750,
  isCallNewCreateShipping: false,
  customerPickAtLocation: false,
  locationName: 'URBAN REVIVO Vincom Đồng Khởi',
  userId: null,
  userName: null,
  isShowLazadaActionButton: false,
  lazadaOrderId: null,
  isShowTikiActionButton: false,
  tikiOrderId: null,
  returnByLocations: null,
  customerInfo: {
    id: 1018000237,
    fullname: '',
    email: 'guest@haravan.com',
    notes: null,
    orderCount: 0,
    totalSpent: 0,
    hasAccount: false,
    phone: null,
    debt: 17461440.8236
  },
  draftCarrierServiceId: null,
  draftCarrierServicePackageId: null,
  utmSource: null,
  utmMedium: null,
  utmCampaign: null,
  utmTerm: null,
  utmContent: null,
  isOrderOldWay: false,
  assignedUserDate: null,
  assignedLocationId: 1706750,
  assignedLocationName: 'URBAN REVIVO Vincom Đồng Khởi',
  assignedLocationDate: '2024-10-15T09:57:44.548Z',
  omniOrderStatus: 10,
  instockConfirmedUserId: null,
  instockConfirmedUserName: null,
  instockConfirmedDate: null,
  outOfStockConfirmedUserName: null,
  outOfStockConfirmedUserId: null,
  outOfStockConfirmedDate: null,
  completedUserName: null,
  completedUserId: 200002479813,
  completedDate: '2024-10-15T09:57:44.548Z',
  cancelUserName: null,
  cancelUserId: null,
  fulfillmentUserName: null,
  fulfillmentUserId: 200002479813,
  shipmentStatus: 4,
  codStatus: 1,
  totalShippingHours: 0,
  paymentInstructionsId: null,
  assignUsersList: null,
  assignGroupUsersList: [
    {
      id: 1023254,
      name: 'Danh sách nhân viên URBAN REVIVO Vincom Đồng Khởi',
      group_type: 2
    }
  ],
  refOrderId: null,
  refOrderNumber: null,
  redeemName: null,
  redeemUsedAmount: 0,
  redeemServiceName: null,
  redeemDiscount: 0,
  redeemDiscountType: null,
  redeemTransactionId: null,
  fulfilledStatus: 2,
  discountTypeId: null,
  checkoutToken: '2c9398623e014fec8e74b33e04fb08a1',
  paymentUrl: null,
  isCanReShipping: false,
  marketPlaceExtraInfoModel: null,
  orderEInvoice: null,
  totalTaxIncluded: 123666.6666,
  totalTaxNotIncluded: 0,
  requestEInvoice: false,
  shippingFirstName: null,
  shippingLastName: null,
  shippingAddress: null,
  shippingAddress2: null,
  shippingCompany: null,
  shippingCountry: null,
  shippingCountryName: null,
  shippingCountryCode: null,
  shippingProvince: null,
  shippingProvinceName: null,
  shippingProvinceCode: null,
  shippingDistrict: null,
  shippingDistrictName: null,
  shippingDistrictCode: null,
  shippingWard: null,
  shippingWardName: null,
  shippingWardCode: null,
  shippingZipCode: null,
  shippingCity: null,
  shippingRegion: null,
  shippingPhone: null,
  shippingMethodName: null,
  billingCompany: null,
  billingFirstName: null,
  billingLastName: null,
  billingAddress: null,
  billingAddress2: null,
  billingPhone: null,
  billingEmail: null,
  billingCountry: null,
  billingCountryName: null,
  billingCountryCode: null,
  billingProvince: null,
  billingProvinceName: null,
  billingProvinceCode: null,
  billingDistrict: null,
  billingDistrictName: null,
  billingDistrictCode: null,
  billingWard: null,
  billingWardName: null,
  billingWardCode: null,
  billingZipCode: null,
  billingCity: null,
  billingRegion: null,
  billingTaxCode: null,
  productTaxIncluded: true,
  shippingTaxIncluded: true,
  shippingTax: 0,
  discountAllocations: [],
  newCheckout: true,
  discount: 0,
  subTotalAfterDiscount: 1669500,
  createdUserName: null
}

export const LOCATION = {
  id: 1706750,
  name: 'URBAN REVIVO Vincom Đồng Khởi',
  locationName: 'URBAN REVIVO Vincom Đồng Khởi',
  address: null,
  email: 'urbanrevivo@gmail.com',
  addressCont: null,
  phone: '1900636099',
  street: '72 Lý Tự Trọng',
  city: '',
  countryId: 241,
  countryName: 'Vietnam',
  provinceId: 50,
  provinceName: 'Hồ Chí Minh',
  postalZipCode: null,
  isPimaryLocation: false,
  isDeleted: false,
  isShippingLocation: true,
  isUnavailableQty: false,
  latitude: null,
  longitude: null,
  districtId: 466,
  wardId: 26740,
  wardName: 'Phường Bến Nghé',
  wardCode: '26740',
  addressDisplay: null,
  districtName: 'Quận 1',
  holdStock: true,
  flexShipHubCode: null,
  proShipHubCode: null,
  ghtkHubCode: null,
  ghnHubCode: null,
  ghNv3HubCode: null,
  shipChungHubCode: null,
  viettelPostHubCode: null,
  dhlHubCode: null,
  viettelPost2018HubCode: null,
  userIds: null,
  groupIds: null,
  typeId: 1,
  areaId: null,
  areaName: null,
  status: 1,
  hubs: null
}

export const DATA_LOCAL_UAT = {
  deviceCode: 'TEST1',
  location: {
    id: 1706750,
    name: 'URBAN REVIVO Vincom Đồng Khởi',
    locationName: 'URBAN REVIVO Vincom Đồng Khởi',
    address: null,
    email: 'urbanrevivo@gmail.com',
    addressCont: null,
    phone: '1900636099',
    street: '72 Lý Tự Trọng',
    city: '',
    countryId: 241,
    countryName: 'Vietnam',
    provinceId: 50,
    provinceName: 'Hồ Chí Minh',
    postalZipCode: null,
    isPimaryLocation: false,
    isDeleted: false,
    isShippingLocation: true,
    isUnavailableQty: false,
    latitude: null,
    longitude: null,
    districtId: 466,
    wardId: 26740,
    wardName: 'Phường Bến Nghé',
    wardCode: '26740',
    addressDisplay: null,
    districtName: 'Quận 1',
    holdStock: true,
    flexShipHubCode: null,
    proShipHubCode: null,
    ghtkHubCode: null,
    ghnHubCode: null,
    ghNv3HubCode: null,
    shipChungHubCode: null,
    viettelPostHubCode: null,
    dhlHubCode: null,
    viettelPost2018HubCode: null,
    userIds: null,
    groupIds: null,
    typeId: 1,
    areaId: null,
    areaName: null,
    status: 1,
    hubs: null
  },
  paymeCode: 'paymetest',
  higd: {
    ip_1: '172.10.10.1:9094',
    ip_2: ''
  }
}

export const DATA_LOCAL_DEV_MODE_UAT = {
  isDisableHigd: true,
  isDisablePayme: true,
  isDisablePrinter: true,
  isDisableRFID: true
}

import { APP_INFO } from '@/src/constants'
import { IAppInfoLocalStorage, ProviderProps } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import { scanner } from '@/src/utils/rfid'
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateDeviceCode } from '@/src/apis/'

type RFID = { barcode: number; EPC: string }

const RFIDContext = createContext<any | undefined>(undefined)

export const RFIDProvider: React.FC<ProviderProps> = ({ children }) => {
  const { t } = useTranslation()
  
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const [port, setPort] = useState<any>(null)
  const [isReadingRFID, setIsReadingRFID] = useState<boolean>(false)
  const [RFID, setRFID] = useState<RFID[]>([])

  const onConnectRFID = async (appInfo: IAppInfoLocalStorage) => {
    try {
      if (port) return

      if (port?.readable) {
        console.log('Port is already open')
        return
      }
      let _port = null

      if (appInfo?.rfid?.vendorId && appInfo?.rfid?.productId) {
        _port = await scanner.connectDevice(appInfo?.rfid.vendorId, appInfo?.rfid.productId)
      } else {
        let device = await scanner.pairDevice()
        if (device) {
          setAppInfo({
            ...appInfo,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          } as IAppInfoLocalStorage)

          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          })

          _port = await scanner.connectDevice(device.usbVendorId, device.usbProductId)
        }
      }

      if (!_port) {
        showToast('warn', t('toast.disconnectRFID'))
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Remove the class after animation duration
        window.location.reload()
      } else {
        setPort(_port)
      }
    } catch (err: any) {
      showToast('warn', err.message === `can't connect to device` ? t('toast.disconnectRFID') : err.message)
      console.log('err', err)
      await new Promise((resolve) => setTimeout(resolve, 1000)) // Remove the class after animation duration
      window.location.reload()
    }
  }

  const onReadAddRFID = useCallback(
    async (bool: boolean) => {
      try {
        if (bool) return
        if (port?.readable && port?.writable && !port?.readable?.locked && !port?.writable?.locked) {
          let tags1 = await scanner.readTags(port)
          let tags2 = await scanner.readTags(port)

          const combinedTags = [...new Map([...tags1, ...tags2].map((item) => [item.tag, item])).values()]

          if (combinedTags?.length > 0) {
            setIsReadingRFID(true)
            setRFID(combinedTags)

            return combinedTags
          }
        }
      } catch (err: any) {
        showToast('warn', err.message === `can't connect to device` ? t('toast.disconnectRFID') : err.message)
        console.log('err', err)
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Remove the class after animation duration
        window.location.reload()
      }
    },
    [isReadingRFID, port]
  )

  const onReadRFID = useCallback(async () => {
    try {
      if (port?.readable && port?.writable && !port?.readable?.locked && !port?.writable?.locked) {
        let tags1 = await scanner.readTags(port)
        let tags2 = await scanner.readTags(port)

        const combinedTags = [...new Map([...tags1, ...tags2].map((item) => [item.tag, item])).values()]
        if (combinedTags?.length > 0) {
          return combinedTags
        }
      }
    } catch (err: any) {
      showToast('warn', err.message === `can't connect to device` ? t('toast.disconnectRFID') : err.message)
      await new Promise((resolve) => setTimeout(resolve, 1000)) // Remove the class after animation duration
      window.location.reload()
    }
  }, [isReadingRFID, port])

  const value = useMemo(
    () => ({
      port,
      isReadingRFID,
      RFID,
      setRFID,
      onConnectRFID,
      onReadAddRFID,
      setIsReadingRFID,
      onReadRFID
    }),
    [port, isReadingRFID, RFID]
  )

  return <RFIDContext.Provider value={value}>{children}</RFIDContext.Provider>
}

export const useRFID = (): any => {
  const context = useContext(RFIDContext)
  if (!context) {
    throw new Error('useRFID must be used within an RFIDProvider')
  }
  return context
}

// closePortIfOpen(usbVendorId, usbProductId) {
//   let port = null;
//   try {
//       if ("serial" in navigator) {
//           const portsGranted = await (navigator as Navigator).serial.getPorts();
//           if (portsGranted && portsGranted.length) {
//               for (const portGrant of portsGranted) {
//                   const portInfo = portGrant.getInfo();
//                   if (portInfo.usbVendorId === usbVendorId && portInfo.usbProductId === usbProductId) {
//                       port = portGrant;
//                   }
//               }
//           }
//       }

//       // Check if the port is open
//       if (port.readable || port.writable) {
//           // Check if there's a reader and release its lock
//           if (port.readable && port.readable.locked) {
//               const reader = port.readable.getReader();
//               await reader.cancel();
//               await reader.releaseLock();
//           }

//           // Check if there's a writer and release its lock
//           if (port.writable && port.writable.locked) {
//               const writer = port.writable.getWriter();
//               await writer.releaseLock();
//           }

//           // Close the port
//           await port.close();
//           console.log('Port closed successfully');
//       } else {
//           console.log('Port is not open');
//       }
//   } catch (error: any) {
//       console.log(error);
//   }

// }

export const INVOICE_COMPANY = [
  {
    id: 0,
    title: 'paymentInvoice.inputCompanyName',
    placeholder: 'paymentInvoice.enterCompanyName',
    key: 'bill_order_company',
    isRequired: true
  },
  {
    id: 1,
    title: 'paymentInvoice.inputAddress',
    placeholder: 'paymentInvoice.enterAddress',
    key: 'bill_order_address',
    isRequired: true
  },
  {
    id: 2,
    title: 'paymentInvoice.inputTaxNumber',
    placeholder: 'paymentInvoice.enterTaxNumber',
    key: 'bill_order_tax_code',
    isRequired: true
  },
  {
    id: 3,
    title: 'paymentInvoice.inputEmail',
    placeholder: 'paymentInvoice.enterEmail',
    key: 'bill_email',
    isRequired: true
  }
]

export const INVOICE_PERSONAL = [
  {
    id: 0,
    title: 'paymentInvoice.inputName',
    placeholder: 'paymentInvoice.enterName',
    key: 'bill_order_company',
    isRequired: true
  },
  {
    id: 1,
    title: 'paymentInvoice.inputAddress',
    placeholder: 'paymentInvoice.enterAddress',
    key: 'bill_order_address',
    isRequired: true
  },
  {
    id: 2,
    title: 'paymentInvoice.inputTaxNumber',
    placeholder: 'paymentInvoice.enterTaxNumber',
    key: 'bill_order_tax_code',
    isRequired: false
  },
  {
    id: 3,
    title: 'paymentInvoice.inputEmail',
    placeholder: 'paymentInvoice.enterEmail',
    key: 'bill_email',
    isRequired: true
  }
]

export const INVOICE_TYPE = [
  {
    id: 0,
    title: 'Personal',
    key: 'paymentInvoice.personal'
  },
  {
    id: 1,
    title: 'Company',
    key: 'paymentInvoice.company'
  }
]

export const INIT_INVOICE_COMPANY = {
  bill_order_company: '',
  bill_order_tax_code: '',
  bill_order_address: '',
  bill_email: ''
}

export const INIT_INVOICE_PERSONAL = {
  bill_order_company: '',
  bill_order_tax_code: '',
  bill_order_address: '',
  bill_email: ''
}

import { Footer, Header } from '@/src/components'
import { APP_INFO, LANGUAGES } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Location: React.FC = () => {
  const { t } = useTranslation()


  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  useEffect(() => {
    i18next.changeLanguage(appInfo?.language || 'vi')
  }, [appInfo?.language])

  const onChangeLanguage = async (newLang: string) => {
    i18next.changeLanguage(newLang)

    setAppInfo({
      ...appInfo,
      language: newLang
    } as IAppInfoLocalStorage)

    showToast('success', t('toast.languageUpdated'))
    // navigate(-1); // Go back to the previous page
  }

  return (
    <div className='layout'>
      <Header title='settings.language' />

      <div className='flex flex-col w-full gap-6 items-center mt-10'>
        {LANGUAGES.map((val, index) => (
          <button
            onClick={() => onChangeLanguage(val.lang)}
            className='w-7/12 flex flex-row justify-between btn-custom bg-blue-50 px-4 py-4 rounded-md items-center'
            key={index}
          >
            <div className='w-4/5 flex gap-4 items-center'>
              <p className='font-bold text-xl'>{val.title}</p>
              <img style={{ height: '2rem' }} src={val.icon} alt={val.title} />
            </div>
            <div className='flex flex-1 justify-end'>
              <div className='flex items-center justify-center w-8 h-8 bg-white rounded-full border border-black'>
                {val.lang === (appInfo?.language || 'vi') && <div className='w-4 h-4 bg-blue-500 rounded-full'></div>}
              </div>
            </div>
          </button>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default Location

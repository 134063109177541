import { IP_ADDRESS_REGEX } from '@/src/constants'

export const isValidatePaymeCode = (input: string): boolean => {
  return !/^\s*$/.test(input)
}
export const isValidateIPAddress = (input: string): boolean => {
  // Kiểm tra nếu chuỗi đầu vào là rỗng
  if (!input.trim()) {
    return true
  }
  // Biểu thức chính quy kiểm tra địa chỉ IPv4 và cổng
  const regex = IP_ADDRESS_REGEX

  // Kiểm tra tính hợp lệ của IP và cổng
  if (regex.test(input)) {
    const port = parseInt(input.split(':')[1], 10) // Tách phần cổng ra để kiểm tra
    // Kiểm tra nếu cổng trong khoảng từ 1 đến 65535
    if (port >= 1 && port <= 65535) {
      return true
    }
  }
  return false
}

export const isValidateCode = (input: string): boolean => {
  if (typeof input !== 'string') {
    return false // or throw an error if you prefer
  }

  if (!input) return false

  const length: number = input.length
  return length > 3 && length <= 255
}

import { logo_black } from '@/src/assets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './styles.css'

interface IHeader {
  title: string
  isBack?: boolean
  onBack?: () => void
}

const Header: React.FC<IHeader> = ({ title, isBack = true, onBack }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onGoHome = () => {
    onBack ? onBack() : navigate('/home')
  }

  return (
    <>
      <div className='z-50 fixed top-0 flex w-full flex-1 bg-white border-b-2 px-8 h-28 gap-20'>
        <div className='flex-1 flex items-center justify-between'>
          <div className='flex flex-1 pr-8'>
            <p className={'responsive-text-small-2'}>{`${t(title)}`}</p>
          </div>
          <img style={{ height: '1.5rem', marginTop: '0.25rem' }} src={logo_black} alt={'logo'} />
        </div>
        <div className={'flex items-center justify-end'}>
          {isBack && (
            <button onClick={onGoHome} className={'btn-custom background-accent100 px-4 py-1 rounded-md'}>
              <p className={'responsive-text-small-3 content-accent700'}>{t('returnToMainPage')}</p>
            </button>
          )}
        </div>
      </div>

      <div className='flex w-full flex h-28' />
    </>
  )
}

export default Header

import { useEffect, useRef, useState } from 'react'
import { INACTIVITY_TIMEOUT } from '@/src/constants'
import { useNavigate } from 'react-router-dom'

// Hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have the same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  // Function to clear the stored value from local storage and reset the state
  const clearValue = () => {
    try {
      // Remove the item from local storage
      window.localStorage.removeItem(key)
      // Reset the state to initialValue
      setStoredValue(initialValue)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  // Function to clear all stored values from local storage
  const clearAllValue = () => {
    try {
      // Clear all items from local storage
      window.localStorage.clear()
      // Optionally reset the state to initialValue for the current key
      setStoredValue(initialValue)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  return [storedValue, setValue, clearValue, clearAllValue] as const
}

export const useInactivityMonitor = () =>
  // onInactive: () => void,
  // timeout: number = 300000 // 5 minutes default
  {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const navigate = useNavigate()

    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        // Call action when inactive
        navigate('/home')
      }, INACTIVITY_TIMEOUT)
    }

    const handleUserAction = () => {
      resetTimeout() // Reset inactivity timer on user action
    }

    useEffect(() => {
      resetTimeout()
      window.addEventListener('mousemove', handleUserAction)
      window.addEventListener('keydown', handleUserAction)
      window.addEventListener('mousedown', handleUserAction)
      window.addEventListener('touchstart', handleUserAction)

      return () => {
        window.removeEventListener('mousemove', handleUserAction)
        window.removeEventListener('keydown', handleUserAction)
        window.removeEventListener('mousedown', handleUserAction)
        window.removeEventListener('touchstart', handleUserAction)
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])
  }

export default useInactivityMonitor

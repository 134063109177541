import { Footer, Header } from '@/src/components'
import { DEV_MODE } from '@/src/constants'
import { useApp } from '@/src/context'
import { IDevModeLocalStorage } from '@/src/types'
import { useLocalStorage } from '@/src/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const INITIAL_VALUE = {
  isDisableHigd: false,
  isDisablePayme: false,
  isDisablePrinter: false,
  isDisableRFID: false
}
const DevMode: React.FC = () => {
  const navigate = useNavigate()

  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, INITIAL_VALUE)
  const [_devMode, _setDevMode] = useState<any>(devMode)
  const { setIsModalPIN } = useApp()

  const onToggleConfig = (key: keyof IDevModeLocalStorage) => {
    _setDevMode((prevState: any) => ({ ...prevState, [key]: !prevState[key] }))
  }

  const onBack = async () => {
    await setIsModalPIN(true)
    navigate('/home')
  }

  const ARRAY_CONFIG = [
    {
      id: 0,
      key: 'HiGD',
      title: 'Tắt cấu hình HiGD',
      onClick: () => onToggleConfig('isDisableHigd')
    },
    {
      id: 1,
      key: 'Payme',
      title: 'Tắt cấu hình Payme',
      onClick: () => onToggleConfig('isDisablePayme')
    },
    {
      id: 1,
      key: 'Printer',
      title: 'Tắt cấu hình Máy in',
      onClick: () => onToggleConfig('isDisablePrinter')
    }
  ]

  useEffect(() => {
    setDevMode({
      ..._devMode
    } as IDevModeLocalStorage)
  }, [_devMode])

  return (
    <div>
      <Header title='settings.devMode' onBack={onBack} />
      <div className='flex flex-col w-full gap-6 items-center	mt-10 '>
        {ARRAY_CONFIG?.map((val, index) => {
          let isSelected = null
          if (val?.key === 'HiGD') isSelected = _devMode.isDisableHigd
          if (val?.key === 'Payme') isSelected = _devMode.isDisablePayme
          if (val?.key === 'Printer') isSelected = _devMode.isDisablePrinter
          return (
            <button
              key={index}
              onClick={val?.onClick}
              className='w-7/12 flex flex-row	justify-between btn-custom bg-blue-50 px-4 py-4 rounded-md items-center'
            >
              <div className='w-4/5 flex flex-col gap-2 items-start'>
                <p className='font-bold text-xl'>{val?.title}</p>
              </div>
              <div className='flex flex-1 justify-end'>
                <div className='flex items-center justify-center w-8 h-8 bg-white rounded-full border  border-black '>
                  {isSelected && <div className='w-4 h-4  bg-blue-500 rounded-full'></div>}
                </div>
              </div>
            </button>
          )
        })}

        <RFIDConfig onToggleConfig={onToggleConfig} _devMode={_devMode} />
      </div>
      <Footer />
    </div>
  )
}

// viewChildren
const RFIDConfig: React.FC<any> = ({ onToggleConfig, _devMode }) => {
  const isSelected = useMemo(() => {
    if (!_devMode) return false
    return _devMode.isDisableRFID
  }, [_devMode])

  return (
    <button
      onClick={() => onToggleConfig('isDisableRFID')}
      className='w-7/12 flex flex-row	justify-between btn-custom bg-blue-50 px-4 py-4 rounded-md items-center'
    >
      <div className='w-4/5 flex flex-col gap-2 items-start'>
        <p className='font-bold text-xl'>Tắt cấu hình RFID</p>
      </div>
      <div className='flex flex-1 justify-end'>
        <div className='flex items-center justify-center w-8 h-8 bg-white rounded-full border  border-black '>
          {isSelected && <div className='w-4 h-4  bg-blue-500 rounded-full'></div>}
        </div>
      </div>
    </button>
  )
}

export default DevMode

interface RFIDItem {
  tag: string
  barcode: string
}
// @ts-ignore
type InputAItem = {
  barcode: string
  qty: number
  epcs: string[]
}

type InputBItem = {
  variantId: number
  quantity: number
}

export const uniqueEpcs = (input: string[]) => {
  return input.filter((item: any, index: any, self: any) => index === self.findIndex((t: any) => t.tag === item.tag))
}
export const groupDataByBarcode = (data: any) => {
  const grouped = data.reduce(
    (acc: any, item: any) => {
      const { barcode, tag } = item

      if (!acc[barcode]) {
        acc[barcode] = {
          barcode: barcode,
          qty: 0,
          epcs: []
        }
      }

      acc[barcode].qty += 1
      acc[barcode].epcs.push(tag)

      return acc
    },
    {} as Record<string, { barcode: string; qty: number; epcs: string[] }>
  )

  return Object.values(grouped)
}

export const createHaravanEpcs = (data: { EPC: string }[]) => {
  const result = []

  for (let i = 0; i < data.length; i += 3) {
    const epcs = data.slice(i, i + 3).map((item: any) => item.tag)

    result.push({
      key: `x-haravan-epcs-0${Math.floor(i / 3) + 1}`,
      value: epcs.toString()
    })
  }

  return result
}

export const compareRFIDArrays = (newRFID: RFIDItem[], oldRFID: RFIDItem[]): boolean => {
  // Kiểm tra độ dài của 2 mảng

  if (newRFID?.length !== oldRFID?.length) {
    console.log('newRFID', newRFID)
    console.log('oldRFID', oldRFID)

    console.log('Error: Mảng NEW_RFID và OLD_RFID có độ dài khác nhau.')
    return false
  }

  // Kiểm tra các phần tử bên trong mảng
  for (const newItem of newRFID) {
    const match = oldRFID.find((oldItem) => oldItem.tag === newItem.tag && oldItem.barcode === newItem.barcode)
    if (!match) {
      console.log(`Error: Không tìm thấy phần tử ${JSON.stringify(newItem)} trong OLD_RFID.`)
      return false
    }
  }

  // Nếu không có lỗi, hai mảng là giống nhau
  return true
}

// Function to compare total quantities
export const compareQuantities = (inputA: any, inputB: InputBItem[]): boolean => {
  const totalQtyA = inputA.reduce((total: any, item: any) => total + item.qty, 0)
  const totalQtyB = inputB.reduce((total: any, item: any) => total + item.quantity, 0)

  return totalQtyA === totalQtyB
}

export const PAYMENT_METHOD = [
  {
    id: 0,
    title: 'VietQR',
    link: '/payment-method/VIETQR'
  },
  {
    id: 1,
    title: 'ATM Card / Credit / Contactless',
    link: '/payment-method/ATM_POS'
  }
  // {
  //   id: 2,
  //   title: 'Credit',
  //   link: '/payment-method/CREDIT_POS',
  // }
]

export const CARRIER_MODEL = {
  id: 0,
  name: '',
  packageId: 1390170,
  packageName: 'Khác',
  isActived: true,
  isShowPackageDimension: true,
  isShowPaymentMethod: false,
  isShowPaidBy: false,
  packageHeight: 0,
  packageLength: 0,
  packageWidth: 0,
  isShowCouponCode: false,
  carrierDiscountCode: '',
  isViewBefore: false,
  isShowViewBefore: true,
  isShownAllowTest: true,
  allowTest: false,
  isViewSenderInfo: false,
  transportType: 0,
  hasInsurance: false,
  paidByReceiver: false,
  isPickupTime: false,
  isPickupDateTime: false,
  codFee: 0,
  fee: 0,
  isInsurance: false,
  insurancePrice: 0
}

export const PAYMENT_TYPE_MODEL = {
  gHNPaymentType: 1,
  gHN2018PaymentType: 1,
  viettelPaymentType: 1,
  shipchungPaymentType: 1,
  proShipPaymentType: 1,
  vNPostPaymentType: 1,
  vNPostPlusPaymentType: 1,
  flexShipPaymentType: 1,
  gHTKPaymentType: 1,
  ninjavanPaymentType: 1,
  isCallNewCreateShipping: true
}

export const LANDING_SITE_REF = 'selfcheckout'

// PAYMENT STATUS

export const PAYMENT_AUTHORIZED = 1 // "Đã xác thực"
export const PAYMENT_PAID = 2 // "Đã thanh toán"
export const PAYMENT_PARTIALLY_REFUNDED = 3 // "Nhập trả một phần"
export const PAYMENT_PARTIALLY_PAID = 4 // "Đã thanh toán một phần"
export const PAYMENT_PENDING = 5 // Chờ xử lý"
export const PAYMENT_REFUNDED = 6 // "Đã nhập trả"
export const PAYMENT_UNPAID = 7 // "Chưa thanh toán"
export const PAYMENT_VOIDED = 8 // "Đã hủy"

export const PRINT_ORDER_TEMPLATE = 1 // "Mẫu in đơn"
export const PRINT_ORDER_DETAIL = 2 // "Mẫu in chi tiết đơn"
export const PRINT_ORDER_TEMP = 3 // "Mẫu in tạm tính"
export const PRINT_ORDER_RETURN = 4 // "Mẫu in đổi trả"

// SHIPMENT STATUS
export const ORDER_READY_TO_PICK = 1 //  "Chờ lấy hàng" //
export const ORDER_PICKING = 2 // Đang đi lấy" //
export const ORDER_DELIVERING = 3 // "Đang giao hàng" //
export const ORDER_DELIVERED = 4 // "Đã giao hàng"
export const ORDER_CANCEL = 5 // "Hủy giao hàng"
export const ORDER_RETURN = 6 // "Chuyển hoàn"
export const ORDER_PENDING = 7 // "Chờ xử lý" //
export const ORDER_NOT_MEET_CUSTOMER = 8 // Không gặp khách"
export const ORDER_WAITING_FOR_RETURN = 9 //  Chờ chuyển hoàn
export const ORDER_PICKUP_FAIL = 12 //  Lấy hàng thất bại

import { Footer, Header } from '@/src/components'
import { PAYMENT_METHOD } from '@/src/constants'
import { useApp } from '@/src/context'
import { formatMoney } from '@/src/utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const PaymentMethod: React.FC = () => {
  const { t } = useTranslation()

  const { cart } = useApp()

  const navigate = useNavigate()

  const onNext = async (link: string) => {
    navigate(link)
  }

  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  return (
    <div className='layout pb-40'>
      <Header title='paymentMethod.payment' />
      <p className='responsive-text-small mt-40'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-16 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text-small mt-20'>{t('paymentMethod.paymentOptions')}</p>
      <p className='responsive-text-small-3 italic mt-5'>{t('paymentMethod.paymentOptionsNote')}</p>

      <div className='flex flex-col gap-6 w-full justify-center items-center mt-24'>
        {PAYMENT_METHOD?.map((val, index) => {
          // return <Button key={index} onClick={() => onNext(val.link)} title={val.title} />
          return (
            <button
              key={index}
              onClick={() => onNext(val.link)}
              className={`'btn-custom' background-accent700 w-4/6 py-9 rounded-lg `}
            >
              <p className={`text-white responsive-text-small-2 `}>{t(val.title)}</p>
            </button>
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

export default PaymentMethod

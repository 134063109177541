import { logo_maison_black } from '@/src/assets'
import { formatDate } from '@/src/utils'
import React, { useMemo } from 'react'
import Barcode from 'react-barcode'
interface IModal {
  order: any
  location: any
}

const formatMoney = (input: number): string => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  })

  // Replace the currency symbol (₫) and any space before it
  return formatter.format(input).replace(/\./g, ',').replace(/\s?₫/, '')
}

const width = (title: string) => {
  let temp = 'w-1/6'
  switch (title) {
    case 'ITEM':
      temp = 'w-[46%]'
      break
    case 'QTY':
      temp = 'w-[10%]'
      break
    case 'PRICE':
    case 'AMOUNT':
      temp = 'w-[22%]'
      break

    default:
      break
  }

  return temp
}

const textAlign = (title: string) => {
  let temp = 'text-start break-all'
  switch (title) {
    case 'ITEM':
      temp = 'text-start break-all'
      break
    case 'QTY':
    case 'PRICE':
      temp = 'text-center break-all'
      break
    case 'AMOUNT':
      temp = 'text-end break-all'
      break

    default:
      break
  }

  return temp
}

const TABLE_HEADER = [
  {
    id: 0,
    title: 'ITEM'
  },
  {
    id: 1,
    title: 'QTY'
  },
  {
    id: 2,
    title: 'PRICE'
  },
  {
    id: 3,
    title: 'AMOUNT'
  }
]

const PrintTemplate: React.FC<IModal> = ({ order, location }) => {
  return (
    <div className='w-full pb-8'>
      <Header order={order} location={location} />
      <Body order={order} />
      <Footer order={order} />
    </div>
  )
}
//viewChildren

const Header: React.FC<any> = ({ order, location }) => {
  const locationAdress = useMemo(() => {
    const { street, wardName, districtName, provinceName } = location
    const parts = [street, wardName, districtName, provinceName].filter((part) => part)

    return parts.join(' , ')
  }, [location])

  return (
    <>
      {/* INFO */}
      <div className='w-full flex flex-col items-center justify-center gap-4 pt-8'>
        <img src={logo_maison_black} width={'200px'} style={{ width: '450px', height: '108px' }} alt='' />
        <p className='text-3xl font-bold'>{location?.locationName}</p>
        <p className='text-2xl font-bold'>MAISON RMI GROUP</p>
      </div>
      <div className='mt-4 flex flex-col'>
        <p className='text-2xl'>
          <b>Địa chỉ :</b> {locationAdress}
        </p>
        <p className='text-2xl'>
          <b>Số điện thoại :</b> {location?.phone}
        </p>
      </div>
      {/* TITLE */}
      <div className='border-dashed border-y-2 border-black mt-4 pb-6 pt-2'>
        <p className='text-2xl font-bold text-center'>INVOICE</p>
      </div>
      <div className='flex flex-col'>
        <p className='text-2xl'>
          <b>INVOICE NO :</b> {order?.orderNumber}
        </p>
        <p className='text-2xl'>
          <b>DATE TIME :</b> {formatDate(order?.createdDate)}
        </p>
        <p className='text-2xl'>
          <b>CUSTOMER ID :</b> {order?.customerInfo?.id}
        </p>
        {order?.customerInfo?.fullname && (
          <p className='text-2xl'>
            <b>NAME :</b> {order?.customerInfo?.fullname}
          </p>
        )}
      </div>
    </>
  )
}
const Body: React.FC<any> = ({ order }) => {
  const { orderProducts } = order

  return (
    <div>
      {/* HEADER */}
      <div className='flex border-dashed border-y-2 border-black mt-8 pb-6 pt-2'>
        {TABLE_HEADER?.map((_val, _index) => {
          return (
            <p key={_index} className={`text-2xl ${width(_val.title)} ${textAlign(_val.title)} font-bold`}>
              {_val.title}
            </p>
          )
        })}
      </div>
      {orderProducts?.map((val: any, index: number) => {
        return (
          <div key={index} className='border-dashed border-b-2 border-black pb-8'>
            <Item val={val} />
          </div>
        )
      })}

      <Summary order={order} orderProducts={orderProducts} />
    </div>
  )
}

const Item: React.FC<any> = ({ val }) => {
  const discountValue = (discount: any, total: any) => {
    const temp = (discount * 100) / total
    return temp.toFixed(2)
  }

  const compareAtPrice = useMemo(() => {
    // const temp = val.properties.find((item: any) => item.name === 'x-pos-compare_at_price')
    // if (temp) {
    //   return Number(temp.value)
    // } else {
    //   return 0
    // }

    return val.oldPrice
  }, [val])

  const fullPrice = useMemo(() => {
    const { originalPrice } = val
    let temp = 0
    if (!compareAtPrice) {
      temp = originalPrice
    } else if (compareAtPrice === originalPrice || compareAtPrice <= originalPrice) {
      temp = originalPrice
    } else if (compareAtPrice > originalPrice) {
      temp = compareAtPrice
    }

    return temp
  }, [val, compareAtPrice])

  const variant = useMemo(() => {
    return val?.variantValue?.split(' / ')
  }, [val])

  return (
    <div className='flex flex-col gap-2'>
      {/* BODY */}
      <div className='flex mt-4'>
        <p className={`text-2xl ${width('ITEM')} ${textAlign('ITEM')}`}>{val.barcode}</p>
        <p className={`text-2xl ${width('QTY')} ${textAlign('QTY')}`}>{val.quantity}</p>
        <p className={`text-2xl ${width('PRICE')} ${textAlign('PRICE')}`}>{formatMoney(val.originalPrice)}</p>
        <p className={`text-2xl ${width('AMOUNT')} ${textAlign('AMOUNT')}`}>
          {formatMoney(val.originalPrice * val.quantity)}
        </p>
      </div>
      <div className='flex justify-between'>
        <p className={`text-2xl`}>
          {variant[2]} - {variant[0]} - {variant[1]}
        </p>
        {compareAtPrice > 0 && compareAtPrice !== val.originalPrice && <p className={`text-2xl`}>M</p>}
      </div>
      <p className={`text-2xl`}>
        {val.productTypeName}, {variant[0]} , {variant[1]}
      </p>
      {/* DISCOUNT */}
      {val?.discountAllocations?.length > 0 && (
        <div className='flex flex-col gap-2'>
          {val?.discountAllocations?.map((_val: any, _index: number) => {
            return (
              <div key={_index} className='flex justify-between'>
                <p className={'text-2xl'}>DISCOUNT @ {discountValue(_val.amount, val.originalPrice * val.quantity)}%</p>
                <p className={'text-2xl'}>-{formatMoney(_val.amount)}</p>
              </div>
            )
          })}
          {/* PRICE */}
          <div className='flex justify-between'>
            <p className={'text-2xl'}>ITEM TOTAL :</p>
            <p className={'text-2xl'}>{formatMoney(val.totalPrice)}</p>
          </div>
        </div>
      )}

      <div className='flex justify-between'>
        <p className={'text-2xl'}>FULL PRICE :</p>
        <p className={'text-2xl'}>{formatMoney(fullPrice)}</p>
      </div>
    </div>
  )
}

const Summary: React.FC<any> = ({ order, orderProducts }) => {
  const grossTotal = useMemo(() => {
    return orderProducts?.reduce((total: number, item: any) => {
      return total + item.quantity * item.originalPrice
    }, 0)
  }, [orderProducts])

  const transactions = useMemo(() => {
    return order.transactions.filter(
      (transaction: any) => transaction.transactionName === 'Xác nhận thanh toán' || transaction.type === 'paid'
    )
  }, [order])

  const totalItems = useMemo(() => {
    return orderProducts?.reduce((total: number, item: any) => {
      return total + item.quantity
    }, 0)
  }, [orderProducts])
  return (
    <>
      {/* FOOTER */}
      {order?.redeemUsedAmount > 0 && (
        <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
          <p className={'text-2xl'}>LOYALTY COIN : </p>
          <p className={'text-2xl'}>{formatMoney(order?.redeemUsedAmount)}</p>
        </div>
      )}

      <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
        <p className={'text-2xl'}>GROSS TOTAL : </p>
        <p className={'text-2xl'}>{formatMoney(grossTotal)}</p>
      </div>
      <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
        <p className={'text-2xl'}>NET TOTAL : </p>
        <p className={'text-2xl'}>{formatMoney(order?.orderTotal)}</p>
      </div>
      {transactions?.length > 0 && (
        <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
          {transactions?.map((val: any, index: number) => {
            return (
              <div key={index} className='flex justify-between w-full'>
                <p className={'text-2xl w-2/3 pr-4'}>
                  {val.paymentMethodName === 'Tiền mặt' ? 'CASH' : val.paymentMethodName} :
                </p>
                <div className='flex gap-2 w-1/3 justify-end'>
                  <p className={'text-2xl'}>VNĐ</p>
                  <p className={'text-2xl'}> {formatMoney(val.amountTransaction)}</p>
                </div>
              </div>
            )
          })}
        </div>
      )}
      {/* COUNT ITEM */}
      <div className='flex flex-col border-dashed border-b-2 border-black  pb-6 pt-2 gap-4'>
        <div className='flex justify-between'>
          <p className={'text-2xl '}>NUMBER OF ITEMS</p>
          <p className={'text-2xl'}>{orderProducts?.length}</p>
        </div>
        <div className='flex justify-between'>
          <p className={'text-2xl '}>TOTAL ITEMS</p>
          <p className={'text-2xl'}>{totalItems}</p>
        </div>
      </div>
    </>
  )
}
const Footer: React.FC<any> = ({ order }) => {
  return (
    <>
      <div className='flex justify-center item-center border-dashed border-b-2 border-black  pb-6 pt-2 gap-4'>
        <img
          src='https://file.hara.vn/200000014460/file/untitled-1.jpg?v=1724923192'
          alt=''
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className='flex flex-col mt-2 gap-2'>
        <p className='text-2xl text-center '>
          Đổi hàng nguyên giá trong 30 ngày kể từ ngày mua theo chính sách Maison.
          <br />
          Không hoàn tiền.
          <br />
          Chi tiết điều khoản áp dụng vui lòng xem tại:
        </p>
        <p className='text-2xl text-center'>https://maisonrmi.com/faqs/</p>
        <div className='flex justify-center items-center mt-4'>
          <Barcode value={order?.orderNumber} displayValue={false} />
        </div>
      </div>
    </>
  )
}

export default PrintTemplate

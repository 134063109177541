import { updateDeviceCode } from '@/src/apis'
import { Footer, Header } from '@/src/components'
import { APP_INFO, KEY_BOARD_TEXT_TYPE_IP } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { isValidateIPAddress, showToast, useLocalStorage } from '@/src/utils'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const INIT_IP_PORT = {
  ip_1: '',
  ip_2: ''
}

const INPUT_IP_PORT = [
  {
    id: 0,
    title: 'settings.higd_1',
    placeholder: 'settings.higd_1',
    key: 'ip_1',
    isRequired: true
  },
  {
    id: 1,
    title: 'settings.higd_2',
    placeholder: 'settings.higd_2',
    key: 'ip_2',
    isRequired: false
  }
]
const HiGD: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const [selectedKey, setSelectedKey] = useState<string>('')
  const [IPPort, setIPPort] = useState<any>(appInfo?.higd ?? INIT_IP_PORT)
  const inputRefs = useRef<any>([])

  const { setIsModalPIN } = useApp()

  const setRef = (index: number, node: any | null) => {
    if (node) {
      inputRefs.current[index] = node
    }
  }

  const onBack = async () => {
    await setIsModalPIN(true)
    navigate('/home')
  }

  const shakeInput = (field: string) => {
    const inputElement = inputRefs.current[field]
    if (inputElement) {
      inputElement.classList.add('animate-shake')
      setTimeout(() => {
        inputElement.classList.remove('animate-shake')
      }, 500)
    }
  }

  const isValidIPPort = async (): Promise<boolean> => {
    let isFormValid = true

    const requiredFields = INPUT_IP_PORT.filter((field) => field.isRequired).map((field) => field.key)

    for (const field of requiredFields) {
      if (!IPPort[field]) {
        shakeInput(field)
        showToast('warn', t('toast.pleaseEnterValue'))
        isFormValid = false
      }
    }

    return isFormValid
  }
  const onNext = async () => {
    const { ip_1, ip_2 } = IPPort

    // Validate IP addresses and port
    const isIPPortValid = await isValidIPPort()
    const isValidIP_1 = await isValidateIPAddress(ip_1)
    const isValidIP_2 = await isValidateIPAddress(ip_2)

    // Handle invalid IP address 1
    if (!isValidIP_1) {
      handleInvalidIP('ip_1')
      return
    }

    // Handle invalid IP address 2
    if (!isValidIP_2) {
      handleInvalidIP('ip_2')
      return
    }

    // Proceed if both IP addresses are valid and IP port is valid
    if (isIPPortValid && isValidIP_1 && isValidIP_2) {
      await updateDeviceCode({
        device_code: appInfo?.deviceCode,
        higd: [ip_1, ip_2].filter((ip) => ip.trim() !== '').join(',')
      })

      // Update local app info and navigate back
      setAppInfo({
        ...appInfo,
        higd: IPPort
      } as IAppInfoLocalStorage)

      navigate(-1)
      showToast('success', t('toast.higdUpdated'))
    }
  }

  // Helper function for invalid IP handling
  const handleInvalidIP = (inputKey: string) => {
    shakeInput(inputKey)
    showToast('warn', t('toast.invalidIP'))
  }

  return (
    <div className='layout pt-40'>
      <Header title='settings.higd' onBack={onBack} />
      <Body
        IPPort={IPPort}
        setIPPort={setIPPort}
        setRef={setRef}
        setPort
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
      />
      <Footer onNext={onNext} nextTitle={'confirm'} />
    </div>
  )
}

const Body: React.FC<any> = ({ IPPort, setIPPort, setRef, setSelectedKey, selectedKey }) => {
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedKey(INPUT_IP_PORT[0].key)
  }, [])

  const onKeyboardClick = (key: string) => {
    switch (key) {
      case 'backspace':
        setIPPort((prevState: any) => ({
          ...prevState,
          [selectedKey]: IPPort[selectedKey].slice(0, -1)
        }))
        break
      case 'reset':
        setIPPort((prevState: any) => ({
          ...prevState,
          [selectedKey]: ''
        }))
        break
      default:
        setIPPort((prevState: any) => ({
          ...prevState,
          [selectedKey]: IPPort[selectedKey] + key
        }))
    }
  }

  const isBold = (key: string) => {
    return key === 'reset' || key === 'backspace'
  }

  return (
    <div className='flex flex-col gap-4 w-full items-center gap-8'>
      {INPUT_IP_PORT?.map((val: any, index: number) => {
        return (
          <div onClick={() => setSelectedKey(val.key)} key={index} className='w-4/6 flex flex-col gap-4'>
            <p className='responsive-text-small'>
              {t(val.title).toUpperCase()} <span className='text-red-500'>{val.isRequired && '*'}</span>{' '}
            </p>

            <div
              ref={(node) => setRef(val.key as any, node)} // Assign ref to each div by key
              className={`w-full p-4 px-2.5 border-2 rounded-lg flex items-center ${selectedKey === val.key && 'border-blue-500 focus:ring-blue-500 focus:border-blue-500'}`}
            >
              {IPPort[val.key] ? (
                <p className='responsive-text-small-2 break-all'>{IPPort[val.key]} </p>
              ) : (
                <p className='text-[#9ca3af] responsive-text-small-2'> {t(val.placeholder)}</p>
              )}
            </div>
          </div>
        )
      })}

      <div className='w-4/6 flex flex-wrap gap-x-5 gap-y-2 mt-10'>
        {KEY_BOARD_TEXT_TYPE_IP.map((val, index) => {
          if (val === 'br') {
            return <div key={index} className='w-full'></div> // Tạo dòng mới
          } else {
            return (
              <button
                onClick={() => onKeyboardClick(val)}
                key={index}
                type='button'
                className={`flex-grow ${isBold(val) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} py-8 rounded-[16px] btn-custom-input`}
              >
                {val === 'backspace' && <Icon title='backspace' />}
                {val === 'reset' && <Icon title='clear' />}
                {val !== 'backspace' && val !== 'reset' && <p className='responsive-text-small'>{val}</p>}
              </button>
            )
          }
        })}
      </div>
    </div>
  )
}

//viewChildren
const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons responsive-text-small'>{title}</i>
}

export default HiGD
